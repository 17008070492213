.changePassTitle {

    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dusk);
}

.label {

    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.input {

    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.choosePassword {
    height: 20px;
    font-size: 16px;
    color: #999ea8;
    margin-top: 30px;
}

.choosePasswordMob {
    font-size: 14px;
}

.rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.rectangleMobile {
    width: 260px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.btnSavePassword {
    object-fit: contain;
}

.changePassRequirements {

    font-size: 13px;
    color: #999ea8;
}

@media (max-width: 768px) {
    .rectangle {
        max-width: 300px;
    }

}
