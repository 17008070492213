.alertContainer {
    margin: 20px 90px;
}

@media (max-width: 1100px) {

    .alertContainer {
        margin: 20px 140px;
    }
}

@media (max-width: 768px) {
    .alertContainer{
        margin: 0 15px;
    }
}
