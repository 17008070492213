@import '../colors.css';

.languageButton {
    cursor: pointer;
    margin: 0px;
    margin-left: 10px;
    padding-right: 10px;
    /* border-right: solid white; */
    color: white;
}

.activeLanguage {
    border-bottom: 0px;
    text-decoration: underline;
    color: var(--carolina-blue);
}

.separator {
    border-right: solid white;
    color: white;
}

.separatorRtl {
    margin: 0px;
    margin-right: 5;
    padding-left: 10px;
    border-left: solid white;
    color: white;
}