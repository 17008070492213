@import "../../colors.css";

.privacyPolicy_container {
    padding: 10px;
    height: calc(100% - 70px);
}

.privacyPolicyTitle {
    height: 29px;
    font-size: 24px;
    color: var(--dusk);
    white-space: pre-line;
}

.applicationPrivacyStatement {
    height: 22px;
    font-size: 18px;
    font-weight: 500;
    color: var(--dusk);
}

.privacyText {
    width: 100%;
    height: 350px;

    font-size: 18px;
    font-weight: 500;
    color: #999ea8;
    resize: none;
}
