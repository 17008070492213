@import "../colors.css";

.title {
    height: 29px;
    font-size: 24px;
    color: var(--dusk);
    white-space: pre-line;
}

.titleError {
    font-size: 22px;
    width: fit-content;
    text-align: center;
    margin: 22px auto;
}

.message {
    height: 22px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    color: #999ea8;
}
