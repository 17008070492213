@import "../../colors.css";

.enterCode {
    font-size: 18px;
    color: #999ea8;
    font-weight: 400;
    margin: 40px 0 2px;
}

.phone {
    direction: ltr;
    font-size: 18px;
    color: var(--dusk);
    font-weight: 400;
}

.btn_continue {
    object-fit: contain;
}

.rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

input.codeInput {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}

.mainTitleMob {
    height: 29px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: var(--dusk);
}

.subitleMob {
    font-size: 14px;
    text-align: center;
    color: #999ea8;
}

.linkButton {
    color: #27AEFF;
    font-size: 18px;
    text-decoration-line: underline;
}

@media (max-width: 768px) {
    .enterCode {
        font-size: 16px;
    }
}

