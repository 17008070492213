.ReplyBody {
    padding-right: 50px;
    padding-left: 50px;
    margin-top: 12px;
    flex: 4;
}

.ReplyBody__Header_Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ReplyBody__Header {
    padding-bottom: 30px;
}

.ReplyBody__Header__Company {
    display: flex;
    align-items: center;
    /*color: var(--salmon);*/
    font-size: 24px;
}

.ReplyBody__Header__Company_Icon {
    width: 50px;
    height: 50px;
}

.ReplyBody__Header__Company_Icon img {
    height: 100%;
}



.ReplyBody__Header__Company__Description  {
    padding: 0 10px;
    color: var(--dusk-two);
}

.AlertHeader {
    color: var(--salmon);
}

.ReplyBody__Header_MuniAddress,
.ReplyBody__Header__Date {
    padding: 2px 0;
    font-size: 14px;
    color: var(--cool-gray);
}

.alertInfoWaterMeter {
    font-size: 18px;
    margin-top: 20px;
    color: var(--dusk-two);
}

.ReplyBody__ReturnButton {
    display: none;
}

.ReplyBody__ReturnButton > img {
    margin: 0 -15px;
}

.ReplyBody__ReturnButton p {
    padding: 0 15px;
    color: var(--azure);
    font-size: 16px;
}


.Visible {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ReplyBody__Content {
    color: var(--cool-gray);
}

.ReplyBody__Content__Request {
    width: 40%;
    overflow-wrap: break-word;
}

.ReplyBody__Content > * {
 margin-bottom: 20px;
}

.ReplyBody__Content__Reply {
    color: var(--dusk-two);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.ReplyBody__Content__Reply p {
    color: var(--dusk-two);
    margin: 0;
}

p.ReplyBody__Content__Reply__Body {
    margin-top: 20px;
}

.alertInfoText {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 45px;
}

.alertsCallContacts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.alertInfoCall {
    font-size: 18px;
    color: var(--dusk);
    flex: 0 0 auto;
}

.alertSep {
    /*width: 100%;*/
    flex: 1 1 auto;
    border: dashed 1px #d0d5d9;
    margin: 0 10px;
}

.alert_ConfirmButton {
    align-self: flex-start;
    background-color: transparent;
    margin-left: -26px;
    margin-right: -16px;
}

@media (max-width: 1200px) {
    .ReplayItem {
        padding: 10px;
    }

    .ReplyBody {
        padding: 0 10px;
    }

    .ReplyBody__Content__Request {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .ReplyBody__ReturnButton {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .ReplyBody {
        padding:10px 0;
    }

    .ReplyBody__Header__Company__Description {
        font-size: 18px;
    }

    .alertInfoWaterMeter{
        margin-top: 5px;
        font-size: 16px;
    }

    .ReplyBody__Header_MuniAddress,
    .ReplyBody__Header__Date{
        font-size: 13px;
    }

    .ReplyBody__Header{
        padding-bottom: 20px;
    }

    .alertsCallContacts {
        display: initial;
    }

    .alertSep{
        display: none;
    }

    .alert_ConfirmButton{
        margin-top: 20px;
    }

    .ReplyBody__Content__Request {
        width: 100%;
    }
}
