@import "../../colors.css";

.termsOfUse_container {
    padding: 10px;
    height: calc(100% - 70px);
}

.termsOfUse {
    height: 29px;
    font-size: 24px;
    color: var(--dusk);
    white-space: pre-line;
}

.websiteTerms {
    height: 22px;

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.acceptance {
    height: 22px;

    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dusk);
}

.termsText {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: normal;
    color: #999ea8;
    resize: none;
}
