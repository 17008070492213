.AlertsContainer {
    margin: 20px 140px 80px;
}

.AlertsContainer__title {
    font-size: 24px;
    font-weight: bold;
    color: var(--dusk-two);
}

.AlertsContainer__Navigation {
    display: flex;
    border-bottom: 1px var(--very-light-pink-two) solid;

}

.NavButton {
    font-size: 18px;
    margin: 20px 0 0 0;
    padding: 15px;
    color: var(--dusk-two);
    cursor: pointer;
}
.active {
    color: var(--azure);
    border-bottom: 4px solid;
}

@media (max-width: 1200px) {
    .AlertsContainer {
        margin: 20px 140px 170px;
    }

}

@media (max-width: 768px) {
    .AlertsContainer .mobileHidden {
        display: none;
    }

    .AlertsContainer__title {
        display: flex;
        justify-content: center;
        font-size: 22px;
    }

    .AlertsContainer__Navigation {
        justify-content: space-around;
        font-size: 16px;
    }

    .AlertsContainer {
        margin: 0 15px;
    }

    .AlertsContainer__title {
        margin-top: 20px;
    }

}
