.landing_container  {
    position: relative;
}

.landing_logoContainer {
    display: flex;
    direction: ltr;
    margin-right: 140px;
}

.lpBgTop {
    width: 820px;
    height: 705px;
    object-fit: contain;
}

.lpBgTopMob {
    width: 100%;
    object-fit: contain;
}

.lpBigImage {
    width: 665px;
    height: 500px;
    object-fit: contain;
}

.lpBigImageMob {
    object-fit: contain;
}

.lpMonitorLabel {
    max-width: 473px;
    font-size: 33px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c63d7;
}

.lpMonitorLabelMob {
    font-size: 27px;
    text-align: center;
    color: #3c63d7;
    padding: 0 30px;
}

.lpButtonsContainer {
    display: flex;
    align-items: center;
    margin-top: 30px;
    justify-content: flex-start;
    /*margin-right: calc(100% - 493px);*/
}

.lpImage {
    object-fit: contain;
    width: 41px;
    height: 42px;
}

.lpButton {
    object-fit: contain;
}

.JoinButton,
.LoginButton {
    padding: 14px 30px;
    background: #003FFF;
    box-shadow: 0 10px 20px rgba(0, 47, 189, 0.2);
    border-radius: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    width: 200px;
    display: flex;
    justify-content: center;
}

.LoginButton {
    color: #003FFF;
    background: #FFFFFF;
    border: 1px solid #003FFF;
}

.LoginButton:hover {
    color: #003FFF;
    background: #f5f0f0;
}

.JoinButton:hover {
    background: #3668fa;
    color: #FFFFFF;
}

.LandingButtonDivider {
    width: 20px;
}

.lpButtonMob {
    object-fit: contain;
    width: 130px;
    height: 70px;
}

.lpButtonTitle {
    height: 27px;
    font-size: 22px;
    font-weight: 700;
    color: #000000;
}

.lpButtonSubTitle {
    width: 219px;
    height: 61px;
    text-align: center;

    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9f9f9f;
}

.alsoAvailable {
     font-size: 24px;
     font-weight: bold;
     font-style: normal;
     font-stretch: normal;
     line-height: normal;
     letter-spacing: normal;
     color: #000000;
 }

.alsoAvailableMob {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    display: flex;
    justify-content: center;
    margin-top: 80px;
}

.Landing_BottomLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Landing_Bottom_TitleLabel {
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;
    margin-top: 10px;
    z-index: 1;
}

.Landing_Bottom_SubtitleLabel {
    font-size: 14px;
    font-weight: 300;
    color: #FFFFFF;
    margin-top: 10px;
    z-index: 1;
}

.Landing_Bottom_background {
    position: absolute;
    bottom: 65px;
    width: 100%;
    z-index: -1;
}

@media (max-width: 1200px) {
    .Landing_Bottom_background {
        bottom: 140px;
    }

}

@media (max-width: 768px) {

    .landing_container  {
        position: initial;
    }

    .Landing_BottomLogo {
        margin: 190px auto 100px;
    }

    .Landing_Bottom_background {
        bottom: 0;
        margin-bottom: -165px;
        overflow-y: hidden;
    }

    .lpButtonSubTitle {
        width: 250px;
    }

    .LandingButtonDivider {
        max-width: 20px;
        flex-basis: 100%;
    }

}

.header_item_landing {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50px;
    right: 150px;
}

