@import '../../colors.css';

.desktopHomeContent {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.vacationDates {
    font-size: 24px;
    font-weight: 700;
    color: var(--dusk);
    margin: 30px 0 15px;
}

.getInformed {
    font-size: 24px;
    font-weight: 700;
    color: #6d6d6d;
    text-align: center;
}

.btnDefineDays {
    width: 200px;
    justify-content: center;
    margin: 30px 0 10px;
}

.btnDefineDays:lang(he) {
    font-size: 18px;
}

.myWaterUsage {
    font-size: 24px;
    font-weight: 700;
    color: var(--dusk);
    margin-bottom: 10px;
}

.myWaterUsage:lang(he) {
    font-size: 20px;
}

.myWaterUsageMob {
    font-size: 18px;
}

.myWaterUsageDate {
    font-size: 16px;
    font-weight: 300;
    color: var(--dusk);
}

.myWaterUsageDate:lang(he),
.waterMeterValue:lang(he) {
    font-weight: 400;
}


.myWaterUsageDateMob {
    font-size: 14px;
}

.waterMetersButton {
    display: flex;
    flex: 1 0 50%;
    height: 100%;
}

.waterUsageSep {
    width: 2px;
    height: 50px;
    background-color: rgba(196, 196, 196, 0.25);
    margin: 0 10px;
}

.waterUsageSepHor {
    height: 2px;
    background-color: rgba(196, 196, 196, 0.25);
    margin: 20px 0;
}

.waterMeterAddress {
    display: flex;
}

.waterMeterTitle {
    font-size: 16px;
    color: var(--dusk);
}

.waterMeterTitle:lang(he) {
    font-size: 20px;
    font-weight: 700;
}


.waterMeterValue {
    font-size: 16px;
    font-weight: 300;
    color: var(--dusk);
    display: flex;
    align-self: flex-start;
    justify-content: center;
    margin-left: 7px;
    margin-right: 7px;
    white-space: nowrap;
    font-family: Open Sans Hebrew, sans-serif;
}

.waterMeterValueMob {
    font-weight: 200;
    font-size: 13px;
}

.thisMonthLabel {
    font-size: 16px;
    color: var(--warm-blue);
}

.thisMonthLabel:lang(he),
.endOfMonthForecastLabel:lang(he){
    font-size: 20px;
    font-weight: 700;
}

.thisMonthValue {
    /* color: #84a2ff;
    font-size: 64px;
    font-weight: 700;
    font-family: Lato, sans-serif; */
    color: #84a2ff;
    font-size: 64px;
    font-weight: 700;
    font-family: Lato, sans-serif;
    /* font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal; */
}

.thisMonthValueGreen {
    /* color: #84a2ff;
    font-size: 64px;
    font-weight: 700;
    font-family: Lato, sans-serif; */
    color: var(--forest-green);
    font-size: 64px;
    font-weight: 700;
    font-family: Lato, sans-serif;
}


.monthlyLimitValueLabel {
    color: #ff7a7a;
    font-size: 64px;
    font-weight: 700;
    font-family: Lato, sans-serif;
}

/*.thisMonthValueMob {*/
/*    font-size: 40px;*/
/*}*/

/*.monthlyLimitValueLabelMob {*/
/*    font-size: 40px;*/
/*}*/

.thisMonthUnits {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--warm-blue);
    align-self: flex-end;
    margin-bottom: 8px;
    padding: 0 5px;
}

.thisMonthUnitsMob {
    font-size: 11px;
}

.endOfMonthForecastLabel {
    font-size: 16px;
    color: #999ea8;
}

.endOfMonthForecastValue {
    font-size: 64px;
    font-weight: 700;
    color: #6d6d6d;
    font-family: Lato, sans-serif;
}

.currentMeterValue {
    font-size: 35px;
    font-weight: 700;
    color: #6d6d6d;
    font-family: Lato, sans-serif;
    margin-top: 30px;
    /* background-image: linear-gradient(to top, #3c63d7 36%, #84a2ff 83%); */
    background-image: linear-gradient(to top, #84a2ff 36%, #3c63d7 83%);
    /* background-image: linear-gradient(to top, #ff7a7a 36%, #fe6060 83%); */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
}

.endOfMonthForecastValue {
    background-image: linear-gradient(to top, #6d6d6d, #ebebeb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.OverLimit {
    background-image: linear-gradient(to top, var(--orange-red), #ebebeb);
}

.Limit {
    background-image: linear-gradient(to top, var(--forest-green) , #ebebeb);
}

.endOfMonthForecastUnits {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
    padding: 0 5px;
    align-self: flex-end;
    margin-bottom: 8px;
}

.endOfMonthForecastUnitsMob {
    font-size: 11px;
}

.monthlyLimitContainer {
    display: flex;
}

.monthlyLimitLabel {
    font-size: 20px;
    font-weight: 700;
    /*letter-spacing: -0.3px;*/
    color: var(--dusk);
}

.monthlyLimitValue {
    font-size: 16px;
    color: #ff7a7a;
    margin: 0 4px;
    font-family: Lato, sans-serif;
}

.monthlyLimitHomeUnits {
    font-size: 16px;
    font-weight: 300;
    color: #999ea8;
    align-self: flex-end;
    padding-bottom: 1px;
}

.seeDetailsButton {
    width: 140px;
    height: 38px;
    object-fit: contain;
    background-color: #ffffff;
    border-radius: 21px;
    font-size: 16px;
    color: var(--dusk);
    margin-top: 10px;
}

.alertInfoContainer {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 15px;
}

.chartBg {
    background-image: linear-gradient(
        to bottom,
        rgba(51, 113, 186, 0.1),
        #f9f9f9 73%
    );
}

.chartBg.chartBgMobile {
    padding: 0;
}

.chartActions {
    display: none;
}

.chartTypesBg {
    border-radius: 100px;
    background-color: #ffffff;
    border: solid 1px #e8e8e8;
}

.chartTypesBgGradient {
    background-image: linear-gradient(
        to top,
        rgba(51, 113, 186, 0.1),
        rgba(247, 248, 250, 0) 73%
    );
}

.link {
    font-size: 13px;
    margin: 10px;
    color: var(--azure);
    cursor: pointer;
    text-decoration: underline;
}

.link:hover {
    color: #003fff;
}

@media only screen {
    .desktopHomeContent {
        margin: 50px 140px 80px;
    }

    .thisMonthValue {
        background-image: linear-gradient(to top, #3c63d7 36%, #84a2ff 83%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .thisMonthValueGreen {
        background-image: linear-gradient(to top, var(--forest-green) 6% , #ebebeb 83%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }



    .monthlyLimitValueLabel {
        background-image: linear-gradient(to top, #ff7a7a 36%, #fe6060 83%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .chartBg {
        padding: 40px 100px;
    }

    .chartActions {
        display: flex;
    }
}

.alertInfoType {
    color: var(--salmon);
    font-size: 24px;
}

.alertTypeLabel{
    color: var(--salmon);
}

.alertDescription,
.alertDate {
    color: var(--cool-gray);
    font-size: 16px;
    font-weight: 300;
}

.alertDescriptionWaterMeter {
    color: var(--dusk-two);
    font-size: 16px;
}

@media (max-width: 1200px) {
    .desktopHomeContent {
        margin: 50px 55px 120px;
    }

    .chartBg {
        padding: 40px;
    }

    .waterMeterValue {
        white-space: initial;
    }

    .waterMetersButton {
        /* flex: 1 1 0; */
    }
}

@media (max-width: 768px) {

    .myWaterUsage:lang(he) {
        font-size: 22px;
    }

    .waterMeterTitle {
        font-size: 14px;
    }

    .waterMeterTitle:lang(he) {
        font-size: 14px;
        font-weight: 400;
    }

    .waterMeterAddress {
        margin-top:10px;
    }

    .waterMeterValue {
        font-size: 13px;
    }

    /* .endOfMonthForecastValue {
        font-size: 38px;
    } */

    .endOfMonthForecastUnits {
        font-size: 11px;
        margin: 0;
        padding-bottom: 2px;
    }

    .monthlyLimitValue {
        padding-top: 5px;
        margin: 0;
    }

    .getInformed {
        font-size: 18px;
        font-weight: 400;
    }

    .vacationDates {
        margin: 20px 0;
        font-size: 20px;
    }


    .alertDescription {
        font-size: 13px;
        padding: 3px 0;
        font-weight: 400;
    }

    .alertDate {
        font-size: 12px;
        font-weight: 400;
    }

    .thisMonthLabel:lang(he),
    .endOfMonthForecastLabel:lang(he){
        font-size: 16px;
        font-weight: 400;
    }

    .myWaterUsageDate:lang(he),
    .waterMeterValue:lang(he) {
        font-weight: 300;
    }

    .btnDefineDays {
        margin: 14px 0 10px;
    }

    .monthlyLimitLabel {
        font-size: 16px;
        font-weight: 500;
        margin: 20px auto;
    }


}


