/*.desktopContactsContent {*/
/*    padding: 40px;*/
/*}*/

/*.mobileContactsContent {*/
/*    padding: 20px 15px;*/
/*}*/

.contactsContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 50px 140px;
    /*direction: rtl;*/
}


.RequestContacts_Container {
    /*direction: rtl;*/
    margin-top: 40px;
    width: 100%;
}

.contactsContainer_title {
    font-size: 24px;
    color: var(--dusk);
    margin-bottom: 0;

}

.RequestContacts_Container p {
    margin: 5px 0;
}

.RequestContacts_row {
    display: flex;
    align-items: center;
    /*direction: rtl;*/
}

.RequestContacts_row p {
    margin: 2px 0;
}

@media (max-width: 768px) {
    .contactsContainer {
        margin: 20px 15px;
    }

}

/*.desktopContactsContent .contactsContainer {*/
/*    width: 60%;*/
/*}*/

/*.contactsInfo {*/
/*    margin: 47px 0;*/
/*}*/

/*.contactsDetailsTitle {*/
/*    margin: 0;*/
/*}*/

/*.detailRow {*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

/*.detailRow p {*/
/*    color: var(--dusk);*/
/*    font-size: 18px;*/
/*    margin-inline-start: 16px;*/
/*}*/

/*.detailRow img {*/
/*    max-width: 24px;*/
/*}*/
