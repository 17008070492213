.desktopAboutContent {
    /*padding: 40px 0;*/
    margin: 50px 140px;
}

.mobileAboutContent {
    padding: 20px 15px;
}

.aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.mobileAboutContent .title,
.desktopAboutContent .title {
    margin-bottom: 47px;
}

.version {
    padding: 0;
    margin-top: -30px;
}

.aboutContainer .row {
    display: flex;
    /*margin: 0 -35px;*/
    flex-wrap: wrap;
}

.mobileAboutContent .aboutContainer .row {
    margin: 0;
}

.aboutInfo {
    flex: 1;
    /*padding: 0 35px;*/
    margin: 0;
}

.aboutContainer_divider {
    width: 35px;
}

.aboutImg {
    /*padding: 0 35px;*/
}

.mobileAboutContent .aboutInfo,
.mobileAboutContent .aboutImg {
    flex: auto;
    width: 100%;
    padding: 0;
    padding-bottom: 20px;
}

@media (max-width: 1350px) {
    .desktopAboutContent {
        margin-bottom: 140px;
    }
}
