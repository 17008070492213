:root {
    --aqua: #1cd3d2;
    --sky-blue: #66c0ff;
    --pale-sky-blue: #c2e8ff;
    --carolina-blue: #8abeff;
    --baby-green: #81fc95;
    --forest-green: #228B22;
    --baby-blue: #a6deff;
    --baby-blue-2: #b0cdff;
    --clear-blue: #2b8fff;
    --clear-blue-2: #2d7bfb;
    --clear-blue-3: #2b74fa;
    --dodger-blue: #4a74f2;
    --dodger-blue-2: #3eb2fa;
    --dodger-blue-3: #5781ff;
    --dodger-blue-Two: #3daefa;
    --dusk: #46557a;
    --dusk-two: #46557a;
    --white: #fafafa;
    --white-2: #fafafa;
    --white-3: #d9d9d9;
    --azure: #27aeff;
    --cool-gray: #999ea8;
    --ice-blue: #eaf4ff;
    --ice-blue-2: #f7faff;
    --salmon: #ff7a7a;
    --silver: #d0d5d9;
    --really-light-blue: #d4efff;
    --vibrant-blue: #003fff;
    --brownish-gray: #726e6e;
    --dark: #1a202f;
    --warm-blue: #3c63d7;
    --orange-red: #ff541e;
    --pinkish-orange: #ff774b;
    --very-light-pink: #fff1f1;
    --very-light-pink-two: #d9d9d9;
    --pale-grey: #edf4ff;
    --lightish-blue: #4074f8;
    --reddish-orange: #f4572c;
}

@value skyBlue: #66c0ff;
