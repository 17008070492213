@import '../../colors.css';

.language-selector {
    position: relative;
    margin-inline-end: 10px;
    justify-content: center;
}

.language-selector__image {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
}

.language-selector__dropdown {
    display: none;
    position: absolute;
    right: 0;
    z-index: 9999;
    background-color: var(--white);
    box-shadow: 0 10px 10px #666;
}

.language-selector__dropdown .language-item:not(:last-child) {
    margin-bottom: 10px;
}

.language-selector__dropdown_opened {
    display: block;
}

.language-item {
    width: 100%;
}

.language-item__text {
    color: black;
}

.language-item_active {
    border-bottom: 0px;
    text-decoration: underline;
    color: var(--carolina-blue);
}
