@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css);
body {
    margin: 0;
    padding: 0;
    font-family:  Lato, sans-serif;
}

#root > *:lang(he),
#root button:lang(he),
#root input:lang(he)
{
    font-family: Open Sans Hebrew, sans-serif;
}

button,
input{
    font-family:  Lato, sans-serif;
}

* {
    box-sizing: border-box;
}

@media print {
    @page {
        size: landscape;
    }

    canvas {
        width: 100% !important;
    }
}

p {
    color: var(--cool-gray);
}

h3 {
    font-size: 18px;
    color: var(--dusk);
}

img {
    max-width: 100%;
}

:root {
    --aqua: #1cd3d2;
    --sky-blue: #66c0ff;
    --pale-sky-blue: #c2e8ff;
    --carolina-blue: #8abeff;
    --baby-green: #81fc95;
    --forest-green: #228B22;
    --baby-blue: #a6deff;
    --baby-blue-2: #b0cdff;
    --clear-blue: #2b8fff;
    --clear-blue-2: #2d7bfb;
    --clear-blue-3: #2b74fa;
    --dodger-blue: #4a74f2;
    --dodger-blue-2: #3eb2fa;
    --dodger-blue-3: #5781ff;
    --dodger-blue-Two: #3daefa;
    --dusk: #46557a;
    --dusk-two: #46557a;
    --white: #fafafa;
    --white-2: #fafafa;
    --white-3: #d9d9d9;
    --azure: #27aeff;
    --cool-gray: #999ea8;
    --ice-blue: #eaf4ff;
    --ice-blue-2: #f7faff;
    --salmon: #ff7a7a;
    --silver: #d0d5d9;
    --really-light-blue: #d4efff;
    --vibrant-blue: #003fff;
    --brownish-gray: #726e6e;
    --dark: #1a202f;
    --warm-blue: #3c63d7;
    --orange-red: #ff541e;
    --pinkish-orange: #ff774b;
    --very-light-pink: #fff1f1;
    --very-light-pink-two: #d9d9d9;
    --pale-grey: #edf4ff;
    --lightish-blue: #4074f8;
    --reddish-orange: #f4572c;
}

@value skyBlue: #66c0ff;

.title {
    color: var(--dusk);
    font-size: 20px;
    text-align: center;
}

.titleMobile {
    font-size: 14px;
}

.eyeButton {
    float: right;
    margin-left: -40px;
    background-color: transparent;
    margin-top: 16px;
    align-self: start;
}

.eyeButton:is(:lang(he), :lang(ar)) {
    margin-right: -40px;
    margin-left: 0;
}

::-webkit-input-placeholder {
    color: #999ea8;
}

::-moz-placeholder {
    color: #999ea8;
    opacity: 1;
}

a {
    color: var(--azure);
    cursor: pointer;
    text-decoration: underline;
}

a:hover {
    color: #003fff;
}

button:hover {
    opacity: 0.7;
}

button:focus {
    outline: none;
}

button {
    outline: none;
    border: 0;
    cursor: pointer;
    background-color: transparent;
}

.blueButton,
.WhiteButton,
.purpleButton {
    padding: 14px 18px;
    background: #003FFF;
    box-shadow: 0 10px 20px rgba(0, 47, 189, 0.2);
    border-radius: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    display: flex;
}

.WhiteButton {
    color: #003FFF;
    background: #FFFFFF;
    border: 1px solid #003FFF;
}

.purpleButton {
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 95px;
    height: 38px;
    /*padding: 9px 12px;*/
    color: #84A2FF;
    background: #FFFFFF;
    border: 1px solid #84A2FF;
}

.WhiteButton:hover {
    color: #003FFF;
    background: #f5f0f0;
}

.purpleButton:hover {
    color: #7998f6;
    background: #f5f0f0;
}

.blueButton:hover {
    background: #3668fa;
    color: #FFFFFF;
}

.transparent {
    background-color: transparent;
}

.inputError {
    color: var(--salmon);
    font-size: 14px;
}

.Site {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.Site-content {
    flex: 1 0 auto;
    padding: 2em 2em 0;
    width: 100%;
}

.Site-content:after {
    content: '\00a0';
    display: block;
    margin-top: 2em;
    height: 0;
    visibility: hidden;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}

.welcomeText {
    margin: auto;
    font-size: 16px;
    padding: 0 20px;
    font-weight: 400;
    color: #ffffff;
}

.sep {
    width: 2px;
    background-image: linear-gradient(to top, #3659c3 19%, #3c63d8 63%);
}

.ToLandingLink {
    display: flex;
    align-self: baseline;
    align-items: center;
    margin-right: 123px;
    margin-left: 123px;
    text-decoration: none;
    margin-top: 5px;
}

.ToLandingLink:hover {
    color: var(--azure);
}

.logoutTabButton {
    margin: 10px 140px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.alertsBellContainer {
    width: 30px;
    height: 30px;
    align-items: center;
    margin: 15px 5px 0 5px;
    display: flex;
    align-self: center;
}

.alertsBell {
    width: 20px;

}

.alertsCounter {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: #ff4f4f;
    display: flex;
    color: #ffffff;
    justify-content: center;
    font-size: 12px;
    top: -4px;
    right: 0px;
    left: 10px;
    position: absolute;
    align-self: flex-start;
    align-items: center;
}

.activeMenuIndicator {
    background-color: #84a2ff;
    height: 3px;
    /*border-bottom: 3px solid  #84a2ff;*/
    width: 100%;
    margin-top: 10px;
}

.dropdown_inputContainer {
    display: flex;
}

.dropdownInput {
    width: 500px;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    text-align: inherit;
    font-size: 18px;
    font-weight: 300;
    color: #999EA8;
    padding: 0 20px;
}

.dropdown_button {
    cursor: pointer;
    position: relative;
    margin: 0 -25px;
    padding: 0 0 2px;
    /*padding: 10px;*/
    z-index: 10;
}

.dropdown_button_close {
    cursor: pointer;
    position: relative;
    margin: 0 -25px;
    padding: 0 0 -2px;
    /*padding: 10px;*/
    z-index: 10;
}


.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    cursor: pointer;
}

.dropdown-content div:hover {
    background-color: #f1f1f1
}

.dropdown-content div {
    /* direction: rtl; */
    color: var(--dusk);
    font-size: 18px;
    padding: 12px 16px;
    display: block;
}

.dropdown2-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    cursor: pointer;
}

.dropdown2-content div:hover {
    background-color: #f1f1f1
}

.dropdown2-content div {
    direction: rtl;
    color: var(--dusk);
    font-size: 18px;
    padding: 12px 16px;
    display: block;
}

.dropdown2:hover .dropdown2-content {
    display: block;
}

/* .dropdown2-content {
    display: block;
} */

.dropdown-content {
    display: block;
}

.visible {
    display: block;
}

.top-bar-desktop {
    padding-inline: 145px;
}

@media (max-width: 1200px) {
    .top-bar-desktop {
        padding-inline: 55px;
    }
}

@media (max-width: 768px) {

    .inputError {
        max-width: 300px;
    }

    .logoutTabButton {
        margin: 10px 15px;
    }


    .ToLandingLink {
        margin: 5px 0;
        font-size: 15px;
    }

    .welcomeText {
        margin: 25px 0;
        padding: 0;
        font-size: 14px;
    }

    .alertsBellContainer {
        margin: -10px 5px 0 5px;
    }

    .NavbarLinkContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex: 1 1;
        height: 30px;
    }

    .activeMenuIndicatorUnderline {
        border-bottom: 3px solid #84a2ff;
    }

    .dropdown {
        width: 100%;
    }
    .dropdown2 {
        width: 100%;
    }

    .dropdownInput {
        width: 100%;
        border: none;
        border-bottom: 1px solid #D0D5D9;
        border-radius: 0;
        border-radius: initial;
        box-shadow: none;
        box-shadow: initial;
        padding: 0;

    }

}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 34px;
  height: 14px;
  padding: 0;
  margin: 4px 6px 5px 7px;
  border-radius: 30px;
  background-color: rgba(34, 31, 31, 0.26);
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: rgba(39, 174, 255, 0.26);
}

.react-toggle--checked .react-toggle-track {
  background-color: rgba(39, 174, 255, 0.26);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: rgba(34, 31, 31, 0.26);
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
  background-color: #27AEFF;
}

.react-toggle--checked .react-toggle-thumb {
  background-color: #27AEFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #27AEFF;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px #0099E0;
}

.header {
    height: 10%;
    padding: 40px 140px 40px 140px;
    display: flex;
    background-image: linear-gradient(
        to top,
        var(--warm-blue) 33%,
        var(--dodger-blue) 77%
    );
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

.header_item {
    display: flex;
    align-items: center;
}

.header_item_title {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px;
}

.header_item_title_rtl {
    margin: auto 10px;
    direction: rtl;
}

.header_languageButton {
    cursor: pointer;
    margin: 10px;
    padding-bottom: 5px;
}

.header_languageButton > img {
    width: 35px;
    height: 20px;
}

.headerMobile {
    padding-left: 0;
}

.logoMWAHeader {
    height: 48px;
    object-fit: contain;
}

.logoMWAHeaderMob {
    object-fit: contain;
}

.headerTitleLabel {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    font-family:  Lato, sans-serif;
}

.headerSubtitleLabel {
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.headerSubtitleLabelMob {
    /**/
    font-size: 15px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.activeLanguage {
    border-bottom: 2px solid;
    color: var(--carolina-blue);
}

@media (max-width: 1200px) {
    .header {
        padding: 40px 55px;
    }
}

@media (max-width: 768px) {
    .header {
        padding: 20px 0px 20px 5px;
    }

    .logoMWAHeader {
        margin: auto 10px;
    }

    .header_item_title {
        -webkit-margin-start: 15px;
                margin-inline-start: 15px;
    }
}

.languageButton {
    cursor: pointer;
    margin: 0px;
    margin-left: 10px;
    padding-right: 10px;
    /* border-right: solid white; */
    color: white;
}

.activeLanguage {
    border-bottom: 0px;
    text-decoration: underline;
    color: var(--carolina-blue);
}

.separator {
    border-right: solid white;
    color: white;
}

.separatorRtl {
    margin: 0px;
    margin-right: 5;
    padding-left: 10px;
    border-left: solid white;
    color: white;
}
.footer {
    display: none;
    background-color: var(--dark);
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: space-between;
    direction: ltr;
    padding: 0 140px;
}

@media only screen {
    .footer {
        display: flex;
        z-index: 10;
    }
}

.footerMobile {
    background-color: var(--dark);
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding: 20px 0 14px 0;
}

.allRights {
    color: var(--dusk);
    font-size: 10px;
}

.footerLink {
    text-decoration: none;
    font-size: 12px;
    color: #ffffff;
    margin: 10px 10px;
    cursor: pointer;
}

.footerLink:hover {
    opacity: 0.7;
    color: #ffffff;
}

.logoMWAFooter {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin: auto 5px;
}

.myWaterAdvisor {
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
    font-family:  Lato, sans-serif;
}

.footerLink_Divider {
    background-color: var(--dodger-blue-3);
    width: 2px;
    margin-left: 8px;
    margin-right: 8px;
    height: 14px;
    align-self: center;
}

@media (max-width: 1200px) {
    .footer {
        flex-direction: column-reverse;
    }
}

@media (max-width: 768px) {
    .footerLink {
        margin: 4px 10px;
        font-size: 14px;
    }
}


.signUpCell {
    width: 628px;
    height: 16%;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
}

.signUpCellMobile {
    height: 16%;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
}

.Sign-up-with-cell-phone-number {
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--azure);
}

.Phone-number-registered-with-your-utility-or-servi {
    height: 29px;

    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.Need-help-Call-your-utility-office {
    height: 20%;

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: normal;
    color: var(--dusk);
}

.Your-water-utility-name-can-be-found-in-the-monthl {
    height: 22px;

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.Your-water-utility-name-can-be-found-in-the-monthl-mobile {
    height: 22px;

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.Rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.RectangleMob {
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.registerTitle {
    color: var(--dusk);
    font-size: 24px;
    text-align: center;
}

.registerTitleMobile {
    font-size: 14px;
}

.Registered-e-mail {
    height: 20px;

    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.Rectangle {
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
    font-size: 16px;
}

.btnLogin {
    object-fit: contain;
}

.subTitleMob {
    height: 39px;

    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.mainTitleMob {
    height: 29px;

    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--dusk);
}

.termsOfUse_container {
    padding: 10px;
    height: calc(100% - 70px);
}

.termsOfUse {
    height: 29px;
    font-size: 24px;
    color: var(--dusk);
    white-space: pre-line;
}

.websiteTerms {
    height: 22px;

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.acceptance {
    height: 22px;

    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dusk);
}

.termsText {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: normal;
    color: #999ea8;
    resize: none;
}

.title {
    height: 29px;
    font-size: 24px;
    color: var(--dusk);
    white-space: pre-line;
}

.titleError {
    font-size: 22px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    margin: 22px auto;
}

.message {
    height: 22px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    color: #999ea8;
}

.Enter-your-e-mail-below-and-we-will-send-you-the-i {
    height: 22px;
    font-size: 18px;
    color: #999ea8;
}

.btn_send_instructions {
    padding: 14px 20px;
    justify-content: center;
    width: 180px;
}

@media (max-width: 768px) {
    .Enter-your-e-mail-below-and-we-will-send-you-the-i {
        font-size: 16px;
        margin: 2px auto 0;
        height: auto;
    }

}


.inputTitle {
    width: 100%;
    /*height: 20px;*/
    font-size: 16px;
    color: #999ea8;
    font-weight: 400;
}

.inputTitleMobile {
    height: 20px;

    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.btnContinue {
    object-fit: contain;
}

.registerDropdown {
    width: 500px;
    font-size: 16px;
    font-weight: 500;
    Padding: 0 10px;
    color: #000;
    color: initial;
    /*letter-spacing: -0.35pt;*/
}


.registerDropdown:-ms-input-placeholder{
    color: #999EA8;
    opacity: 1;
}


.registerDropdown::placeholder,
.registerDropdown:-ms-input-placeholder,
.registerDropdown::-ms-input-placeholder{
    color: #999EA8;
    opacity: 1;
}

.placeholder {
    color: #999EA8;
}

.dropdown-content-register div {
    font-size: 16px;
}

@media (max-width: 768px) {

    .RegisterForm {
        margin: 0 15px;
        width: 100%;
    }

    .inputTitle {
        font-size: 14px;
    }


    .registerFinish_Container {
        margin: 20px 15px;
    }

    .registerDropdown {
        width: 100%;
        border: 1px solid #E0E0E0;
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        margin: 0 0 10px;
    }


}

.enterPrimary {
    height: 22px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.enterPrimaryMobile {
    height: 22px;

    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.rectangleMobile {
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.btn_get_code {
    object-fit: contain;
}

.country {
    font-size: 18px;
    font-weight: 300;
    color: #999ea8;
    border: none;
    outline: none;
    /*float: left;*/
    margin-left: 8px;
}

.phoneBox {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    max-width: 330px;
    width: 100%;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.phoneInput:focus {
    outline: 0;
}

.enterCode {
    font-size: 18px;
    color: #999ea8;
    font-weight: 400;
    margin: 40px 0 2px;
}

.phone {
    direction: ltr;
    font-size: 18px;
    color: var(--dusk);
    font-weight: 400;
}

.btn_continue {
    object-fit: contain;
}

.rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

input.codeInput {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}

.mainTitleMob {
    height: 29px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: var(--dusk);
}

.subitleMob {
    font-size: 14px;
    text-align: center;
    color: #999ea8;
}

.linkButton {
    color: #27AEFF;
    font-size: 18px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}

@media (max-width: 768px) {
    .enterCode {
        font-size: 16px;
    }
}


.accountNotFound {
    height: 22px;

    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #46557a;
    color: var(--dusk);
}

.tryAnother {
    height: 22px;

    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.registerFinish_Container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 180px;
}

.passwordRulesText {
    height: 16px;
    font-size: 13px;
    font-weight: normal;
    color: #999ea8;
}

.signingUp {
    /*height: 22px;*/
    font-weight: 400;
    font-size: 18px;
    color: #999ea8;
    margin: 0;
}

.btnFinishAndLogin {
    object-fit: contain;
}

.rectangleRegisterFinish {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

@media (max-width: 768px) {
    .signingUp {
        font-size: 16px;
    }

    .rectangleRegisterFinish {
        max-width: 330px;
    }

    .registerFinish_Container {
        margin-bottom: 20px;
    }
}

.choosePassword {
    height: 20px;

    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.titleMobilerectangleMobile {
    width: 280px;
}

.btnSavePassword {
    object-fit: contain;
}

.titleMobile {
    font-size: 14px;
    color: var(--dusk);
    text-align: center;
}

.settingsContainer {
    display: flex;
    padding: 50px 140px 200px;
}

.settingsContainer_params {
    display: flex;
    flex: 5 1;
    flex-direction: column;
    align-items: flex-start;
}

.settingsContainer_params_togglesContainer{
    padding: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.settingsContainer_params_togglesContainer > div:nth-child(1) * {
    color: var(--dusk);
}

.settingsContainer_params_togglesContainer_row {
    display: flex;
    align-items: center;
    width: 100%;
}

.settingsContainer_params_togglesContainer_row_header {
    background-color: #f7faff;
}

/*.title {*/
/*    font-size: 24px;*/
/*    color: var(--dusk);*/
/*}*/

.alertParamsTitle {
    font-weight: 700;
}

.alertSettingsRectangle {
    height: 48px;
    background-color: #f7faff;
}

.alertTitle {
    font-size: 16px;
    padding: 14px 0;
    color: var(--dusk);
    min-width: 160px;
}

.alertType {
    font-size: 16px;
    padding: 14px 30px;
    color: #999ea8;
    flex-grow: 1;
}

.alertToggle {
    min-width: 220px;
    display: flex;
    justify-content: center;
}

.settingsContainer_params_meters {
    margin-top: 10px;
}

.settingsContainer_params_meters_header {
    display: flex;
    padding: 0 30px;
}

.settingsContainer_params_meters_list {
    margin: 30px;
}

.settingsContainer_params_meters_list_row {
    display: flex;
}

.settingsContainer_account {
    display: flex;
    flex: 4 1;
    justify-content: flex-end;
}

.accountBox {
    flex: 0.8 1;
    padding: 30px 15px 10px;
    background-color: #f9f9f9;
    height: -webkit-max-content;
    height: max-content;
}

.accountBox_buttonWrapper{
    width: 100%;
    direction: ltr;
    margin: 0 15px;
}

.accountBox_buttonWrapper:lang(he) {
    margin: 0;
    padding: 0 30px;
}

.settingsContainer_account_button {
    margin-top: 20px;
    align-self: flex-start;
    width: 257px;
}

.settingsContainer_account_button:lang(he) {

}

.settingsContainer_account_button img {
    height: 22px;
}

.buttonDivider {
    width: 12px;
}

.buttonDivider:lang(he) {
    width: 30px;
}

.buttonText {
    flex: 1 1;
}

.accountKey {
    margin: 10px 0;
    font-size: 18px;
    color: #999ea8;
}

.accountLink {
    color: var(--azure);
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
}

.accountKeyMobile {
    font-size: 18px;
    color: #999ea8;
}

.accountValue {
    margin: 10px 0;
    font-size: 18px;
    color: var(--dusk);
    height: 22px;
}

.phoneNumber {
    direction: ltr;
}

.thinSep {
    width: 100%;
    height: 1px;
    background-color: #d0d5d9;
}

.myWaterMeters {
    font-size: 18px;
    color: var(--dusk);
    margin: 0 15px;
}

.waterMeterItem {
    font-size: 13px;
    color: var(--dusk);
}

.waterMeterItem:first-child {
    -webkit-margin-end: 5px;
            margin-inline-end: 5px;
}

.btnPassword {
    object-fit: contain;
}

@media (max-width: 1350px) {
    .settingsContainer {
        flex-direction: column;
    }

    .settingsContainer_params{
        align-items: center;
    }

    .title {
        width: 100%;
    }

    .alertToggle {
        min-width: 120px;
    }

    .settingsContainer_account {
        justify-content: center;
    }

    .accountBox_buttonWrapper {
        margin: 0;
    }

    .settingsContainer_account_button{
        margin: 20px auto 0;

    }
}

@media (max-width: 768px) {
    .alertToggle {
        min-width: 100px;
    }

    .settingsContainer_params_togglesContainer {
        width: auto;
    }

    .settingsContainer_account {
        width: 100%;
        justify-content: unset;
    }

    .accountBox {
        flex: unset;
        width: 100%;
    }

    .settingsContainer {
        padding: 20px 15px;
    }

    .title {
        font-size: 22px;
    }

    .accountKey {
        font-size: 16px;
    }

}

.desktopHomeContent {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.vacationDates {
    font-size: 24px;
    font-weight: 700;
    color: var(--dusk);
    margin: 30px 0 15px;
}

.getInformed {
    font-size: 24px;
    font-weight: 700;
    color: #6d6d6d;
    text-align: center;
}

.btnDefineDays {
    width: 200px;
    justify-content: center;
    margin: 30px 0 10px;
}

.btnDefineDays:lang(he) {
    font-size: 18px;
}

.myWaterUsage {
    font-size: 24px;
    font-weight: 700;
    color: var(--dusk);
    margin-bottom: 10px;
}

.myWaterUsage:lang(he) {
    font-size: 20px;
}

.myWaterUsageMob {
    font-size: 18px;
}

.myWaterUsageDate {
    font-size: 16px;
    font-weight: 300;
    color: var(--dusk);
}

.myWaterUsageDate:lang(he),
.waterMeterValue:lang(he) {
    font-weight: 400;
}


.myWaterUsageDateMob {
    font-size: 14px;
}

.waterMetersButton {
    display: flex;
    flex: 1 0 50%;
    height: 100%;
}

.waterUsageSep {
    width: 2px;
    height: 50px;
    background-color: rgba(196, 196, 196, 0.25);
    margin: 0 10px;
}

.waterUsageSepHor {
    height: 2px;
    background-color: rgba(196, 196, 196, 0.25);
    margin: 20px 0;
}

.waterMeterAddress {
    display: flex;
}

.waterMeterTitle {
    font-size: 16px;
    color: var(--dusk);
}

.waterMeterTitle:lang(he) {
    font-size: 20px;
    font-weight: 700;
}


.waterMeterValue {
    font-size: 16px;
    font-weight: 300;
    color: var(--dusk);
    display: flex;
    align-self: flex-start;
    justify-content: center;
    margin-left: 7px;
    margin-right: 7px;
    white-space: nowrap;
    font-family: Open Sans Hebrew, sans-serif;
}

.waterMeterValueMob {
    font-weight: 200;
    font-size: 13px;
}

.thisMonthLabel {
    font-size: 16px;
    color: var(--warm-blue);
}

.thisMonthLabel:lang(he),
.endOfMonthForecastLabel:lang(he){
    font-size: 20px;
    font-weight: 700;
}

.thisMonthValue {
    /* color: #84a2ff;
    font-size: 64px;
    font-weight: 700;
    font-family: Lato, sans-serif; */
    color: #84a2ff;
    font-size: 64px;
    font-weight: 700;
    font-family: Lato, sans-serif;
    /* font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal; */
}

.thisMonthValueGreen {
    /* color: #84a2ff;
    font-size: 64px;
    font-weight: 700;
    font-family: Lato, sans-serif; */
    color: var(--forest-green);
    font-size: 64px;
    font-weight: 700;
    font-family: Lato, sans-serif;
}


.monthlyLimitValueLabel {
    color: #ff7a7a;
    font-size: 64px;
    font-weight: 700;
    font-family: Lato, sans-serif;
}

/*.thisMonthValueMob {*/
/*    font-size: 40px;*/
/*}*/

/*.monthlyLimitValueLabelMob {*/
/*    font-size: 40px;*/
/*}*/

.thisMonthUnits {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--warm-blue);
    align-self: flex-end;
    margin-bottom: 8px;
    padding: 0 5px;
}

.thisMonthUnitsMob {
    font-size: 11px;
}

.endOfMonthForecastLabel {
    font-size: 16px;
    color: #999ea8;
}

.endOfMonthForecastValue {
    font-size: 64px;
    font-weight: 700;
    color: #6d6d6d;
    font-family: Lato, sans-serif;
}

.currentMeterValue {
    font-size: 35px;
    font-weight: 700;
    color: #6d6d6d;
    font-family: Lato, sans-serif;
    margin-top: 30px;
    /* background-image: linear-gradient(to top, #3c63d7 36%, #84a2ff 83%); */
    background-image: linear-gradient(to top, #84a2ff 36%, #3c63d7 83%);
    /* background-image: linear-gradient(to top, #ff7a7a 36%, #fe6060 83%); */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
}

.endOfMonthForecastValue {
    background-image: linear-gradient(to top, #6d6d6d, #ebebeb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.OverLimit {
    background-image: linear-gradient(to top, var(--orange-red), #ebebeb);
}

.Limit {
    background-image: linear-gradient(to top, var(--forest-green) , #ebebeb);
}

.endOfMonthForecastUnits {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
    padding: 0 5px;
    align-self: flex-end;
    margin-bottom: 8px;
}

.endOfMonthForecastUnitsMob {
    font-size: 11px;
}

.monthlyLimitContainer {
    display: flex;
}

.monthlyLimitLabel {
    font-size: 20px;
    font-weight: 700;
    /*letter-spacing: -0.3px;*/
    color: var(--dusk);
}

.monthlyLimitValue {
    font-size: 16px;
    color: #ff7a7a;
    margin: 0 4px;
    font-family: Lato, sans-serif;
}

.monthlyLimitHomeUnits {
    font-size: 16px;
    font-weight: 300;
    color: #999ea8;
    align-self: flex-end;
    padding-bottom: 1px;
}

.seeDetailsButton {
    width: 140px;
    height: 38px;
    object-fit: contain;
    background-color: #ffffff;
    border-radius: 21px;
    font-size: 16px;
    color: var(--dusk);
    margin-top: 10px;
}

.alertInfoContainer {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 15px;
}

.chartBg {
    background-image: linear-gradient(
        to bottom,
        rgba(51, 113, 186, 0.1),
        #f9f9f9 73%
    );
}

.chartBg.chartBgMobile {
    padding: 0;
}

.chartActions {
    display: none;
}

.chartTypesBg {
    border-radius: 100px;
    background-color: #ffffff;
    border: solid 1px #e8e8e8;
}

.chartTypesBgGradient {
    background-image: linear-gradient(
        to top,
        rgba(51, 113, 186, 0.1),
        rgba(247, 248, 250, 0) 73%
    );
}

.link {
    font-size: 13px;
    margin: 10px;
    color: var(--azure);
    cursor: pointer;
    text-decoration: underline;
}

.link:hover {
    color: #003fff;
}

@media only screen {
    .desktopHomeContent {
        margin: 50px 140px 80px;
    }

    .thisMonthValue {
        background-image: linear-gradient(to top, #3c63d7 36%, #84a2ff 83%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .thisMonthValueGreen {
        background-image: linear-gradient(to top, var(--forest-green) 6% , #ebebeb 83%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }



    .monthlyLimitValueLabel {
        background-image: linear-gradient(to top, #ff7a7a 36%, #fe6060 83%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .chartBg {
        padding: 40px 100px;
    }

    .chartActions {
        display: flex;
    }
}

.alertInfoType {
    color: var(--salmon);
    font-size: 24px;
}

.alertTypeLabel{
    color: var(--salmon);
}

.alertDescription,
.alertDate {
    color: var(--cool-gray);
    font-size: 16px;
    font-weight: 300;
}

.alertDescriptionWaterMeter {
    color: var(--dusk-two);
    font-size: 16px;
}

@media (max-width: 1200px) {
    .desktopHomeContent {
        margin: 50px 55px 120px;
    }

    .chartBg {
        padding: 40px;
    }

    .waterMeterValue {
        white-space: normal;
        white-space: initial;
    }

    .waterMetersButton {
        /* flex: 1 1 0; */
    }
}

@media (max-width: 768px) {

    .myWaterUsage:lang(he) {
        font-size: 22px;
    }

    .waterMeterTitle {
        font-size: 14px;
    }

    .waterMeterTitle:lang(he) {
        font-size: 14px;
        font-weight: 400;
    }

    .waterMeterAddress {
        margin-top:10px;
    }

    .waterMeterValue {
        font-size: 13px;
    }

    /* .endOfMonthForecastValue {
        font-size: 38px;
    } */

    .endOfMonthForecastUnits {
        font-size: 11px;
        margin: 0;
        padding-bottom: 2px;
    }

    .monthlyLimitValue {
        padding-top: 5px;
        margin: 0;
    }

    .getInformed {
        font-size: 18px;
        font-weight: 400;
    }

    .vacationDates {
        margin: 20px 0;
        font-size: 20px;
    }


    .alertDescription {
        font-size: 13px;
        padding: 3px 0;
        font-weight: 400;
    }

    .alertDate {
        font-size: 12px;
        font-weight: 400;
    }

    .thisMonthLabel:lang(he),
    .endOfMonthForecastLabel:lang(he){
        font-size: 16px;
        font-weight: 400;
    }

    .myWaterUsageDate:lang(he),
    .waterMeterValue:lang(he) {
        font-weight: 300;
    }

    .btnDefineDays {
        margin: 14px 0 10px;
    }

    .monthlyLimitLabel {
        font-size: 16px;
        font-weight: 500;
        margin: 20px auto;
    }


}



.react-date-picker {
  display: inline-flex;
  position: relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  box-sizing: border-box;
}
.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  /*border: thin solid gray;*/
  margin: 5px -5px 0 -5px;
  border: none;
  flex-direction: row-reverse;

}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
  font-size: 14px;
  color: var(--cool-gray);
  pointer-events: none;
  display: flex;
  /*flex-direction: row-reverse;*/
  align-items: baseline;
  /*justify-content: flex-end;*/
}
.react-date-picker.react-date-picker--open.react-date-picker--enabled > div > div > select,
.react-date-picker.react-date-picker--closed.react-date-picker--enabled > div > div > select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  /*margin: 0 3px;*/
  text-overflow: '';
}


.react-date-picker__inputGroup__divider {
  padding: 3px 1px;
  /*white-space: pre;*/
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
  color: var(--cool-gray);
  pointer-events: none;
}

.react-date-picker__inputGroup__leadingZero {
}

select .react-date-picker__inputGroup__input {
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: transparent;
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px +  0.54em);
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.setMonthlyTitle {

    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dusk);
}

.monthlyLimitUnits {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.btnSave {
    object-fit: contain;
}

.borderRect {
    width: 97px;
    height: 38px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #d0d5d9;
}

:root {
    --aqua: #1cd3d2;
    --sky-blue: #66c0ff;
    --pale-sky-blue: #c2e8ff;
    --carolina-blue: #8abeff;
    --baby-green: #81fc95;
    --forest-green: #228B22;
    --baby-blue: #a6deff;
    --baby-blue-2: #b0cdff;
    --clear-blue: #2b8fff;
    --clear-blue-2: #2d7bfb;
    --clear-blue-3: #2b74fa;
    --dodger-blue: #4a74f2;
    --dodger-blue-2: #3eb2fa;
    --dodger-blue-3: #5781ff;
    --dodger-blue-Two: #3daefa;
    --dusk: #46557a;
    --dusk-two: #46557a;
    --white: #fafafa;
    --white-2: #fafafa;
    --white-3: #d9d9d9;
    --azure: #27aeff;
    --cool-gray: #999ea8;
    --ice-blue: #eaf4ff;
    --ice-blue-2: #f7faff;
    --salmon: #ff7a7a;
    --silver: #d0d5d9;
    --really-light-blue: #d4efff;
    --vibrant-blue: #003fff;
    --brownish-gray: #726e6e;
    --dark: #1a202f;
    --warm-blue: #3c63d7;
    --orange-red: #ff541e;
    --pinkish-orange: #ff774b;
    --very-light-pink: #fff1f1;
    --very-light-pink-two: #d9d9d9;
    --pale-grey: #edf4ff;
    --lightish-blue: #4074f8;
    --reddish-orange: #f4572c;
}

@value skyBlue: #66c0ff;

.dailyUsage {
    font-family: Lato;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.setMonthlyTitle {
    font-size: 24px;
    margin: 30px 0;
}

.dailyUsage {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.borderRect {
    font-size: 16px;
    direction: ltr;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    text-align: center;
}

.fromTo {
    font-size: 14px;
    color: var(--dusk);
}

.fromTo:lang(he) {
    /*font-weight: 700;*/
}

.chooseDateInput {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
    border: 0;
    margin-left: 10px;
    margin-right: 10px;
    width: 135px;
    background-color: #ffffff;
}

.datePickerDivider {
    background-color: var(--silver);
    width: 1px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    margin-top: 20px;
}

.vacationSep {
    height: 24px;
    width: 1px;
    background-color: #d0d5d9;
}

.monthlyLimitToggleContainer {
    display: flex;
    align-items: center;
    margin: 0 25px;
}

.Vacation_ButtonsContainer {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: transparent;
}

.Vacation_ButtonsContainer button {
    margin: 0 -15px;
}


@media (max-width: 768px) {
    .Vacation_ButtonsContainer button {
        margin: 0;
    }

    .setMonthlyTitle {
        font-size: 20px;
        text-align: center;
    }

    .dailyUsage {
        text-align: center;
    }

    .monthlyLimitToggleContainer {
        margin: 0 10px;
    }

    .borderRect {
        margin-top: 8px;
    }

    .fromTo {

    }

    .datePicker {
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-left: 15px;
        margin-right: 15px;
        padding: 0;
        background-color: transparent;
    }

    .chooseDateInput {
        width: 100px;
    }

    .datePickerDivider {
        margin: 20px 10px 0 10px;
    }

    .datePickerHorizontalDivider {
        height: 10px;
        border-bottom: 1px solid var(--silver);
    }


    .toggleContainer {
        margin-top: 20px;
        display: flex;
    }
}


.fromTo {
    font-family: Lato;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dusk);
}

.chooseDateInput {
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.vacationSep {
    height: 24px;
    width: 1px;
    background-color: #d0d5d9;
}

.waterConsumption {
    background-image: linear-gradient(to top, #3eb3f9, #2b74fa);
}

.meVsSimilar {
    background-color: #d9d9d9;
}

.previousYear2016 {
    background-image: linear-gradient(to top, #c6c6c6, #8d8d8d);
}

.previousYear2017 {
    background-image: linear-gradient(to top, #1fede0, #18b6c0);
}

.previousYear2018 {
    background-image: linear-gradient(to top, #3eb3f9, #2b74fa);
}

.chartAreaWrapper {
    overflow-x: scroll;
}

.chartLegend_Wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: -120px;
    margin-left: 40px;
    /*margin-right: 40px;*/
}

.chartLegendColumnDescription {
    display: flex;
    justify-content: flex-start;
}

.chartLegendColumnDescription * {
    display: flex;
    align-items: center;
}

.chartLegendColumnDescription > div > p {
    margin: 30px 10px;
    color: var(--dusk-two);
    font-size: 18px;
}

.chartLegendColumnDescription_divider {
    width: 40px;
}

.estimateBox,
.realBox,
.belowBox,
.underBox {
    width: 16px;
    height: 16px;
}

.belowBox {
    background-color: var(--lightish-blue);
}

.underBox {
    background-color: var(--reddish-orange);
}

.estimateBox {
    background-color: var(--dodger-blue-Two);
}

.realBox {
    background-color: var(--aqua);
}

.chartLegendStatistics {
    display: flex;
    margin: 0px -20px;
}

.borderBottom {
    border-bottom-color: red;
    border-bottom-style: solid;
    border-bottom-width: thick;
}

.chartLegendItem {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    font-size: 18px;
    color: var(--cool-gray);
}

.chartLegend_ValuesContainer {
    display: flex;
    align-items: baseline;
    font-size: 30px;
    color: var(--azure);
}

.chartLegend_ValuesContainer > div:nth-child(1) {
    font-family: Lato, sans-serif;
}

.chartLegendUnits {
    font-size: 13px;
    margin: 0 3px;
    color: var(--cool-gray);
}

@media (max-width: 768px) {
    .chartLegendUnits {
        font-size: 11px;
    }

    .chartLegendItem {
        margin: 0 10px;
    }

    .chartLegend_Wrapper {
        width: 100%;
        margin-top: -90px;
        margin-left: 0;
        margin-right: 0;
    }

    .chartLegendColumnDescription {
        justify-content: center;
    }

    .chartLegendStatistics {
        flex-wrap: wrap;
        grid-row-gap: 20px;
        row-gap: 20px;
        margin-left: 0;
        margin-right: 0;
        justify-content: space-around;
    }

    .chartLegendColumnDescription > div > p {
        font-size: 15px;
    }

    .chartLegendItem {
        font-size: 15px;
    }

    .chartLegend_ValuesContainer {
        font-size: 18px;
    }

}

.changePassTitle {

    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dusk);
}

.label {

    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.input {

    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.choosePassword {
    height: 20px;
    font-size: 16px;
    color: #999ea8;
    margin-top: 30px;
}

.choosePasswordMob {
    font-size: 14px;
}

.rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.rectangleMobile {
    width: 260px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.btnSavePassword {
    object-fit: contain;
}

.changePassRequirements {

    font-size: 13px;
    color: #999ea8;
}

@media (max-width: 768px) {
    .rectangle {
        max-width: 300px;
    }

}

.title {

    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dusk);
}

.rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.rectangleMob {
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.country {
    font-size: 18px;
    font-weight: 300;
    /*font-style: normal;*/
    /*font-stretch: normal;*/
    /*line-height: normal;*/
    /*letter-spacing: normal;*/
    color: #999ea8;
}

.phoneInput:focus {
    outline: 0;
}

.AlertsContainer {
    margin: 20px 140px 80px;
}

.AlertsContainer__title {
    font-size: 24px;
    font-weight: bold;
    color: var(--dusk-two);
}

.AlertsContainer__Navigation {
    display: flex;
    border-bottom: 1px var(--very-light-pink-two) solid;

}

.NavButton {
    font-size: 18px;
    margin: 20px 0 0 0;
    padding: 15px;
    color: var(--dusk-two);
    cursor: pointer;
}
.active {
    color: var(--azure);
    border-bottom: 4px solid;
}

@media (max-width: 1200px) {
    .AlertsContainer {
        margin: 20px 140px 170px;
    }

}

@media (max-width: 768px) {
    .AlertsContainer .mobileHidden {
        display: none;
    }

    .AlertsContainer__title {
        display: flex;
        justify-content: center;
        font-size: 22px;
    }

    .AlertsContainer__Navigation {
        justify-content: space-around;
        font-size: 16px;
    }

    .AlertsContainer {
        margin: 0 15px;
    }

    .AlertsContainer__title {
        margin-top: 20px;
    }

}


.Requests {
    display: flex;
    margin-top: 30px;
}

.Requests_listContainer,
.Requests_listContainer_hidden {
    display: flex;
    flex-flow: wrap column;
    flex: 3 1;
    height: calc(100vh - 450px);
}

.Requests_listContainer ul {
    overflow-y: auto;
    padding: 1px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.ReplaysList {
    overflow-y: auto;
}

.ReplaysList_hidden {
    flex: 3 1;
    overflow-y: auto;
    padding: 1px;
}

.ReplayItem {
    width: 470px;
    display: flex;
    justify-content: space-between;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--pale-grey);
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    border-top: 1px solid var(--pale-grey);
    outline: 1px solid transparent;
    padding: 10px 40px;
    cursor: pointer;
    margin: 0px 20px 0 0px;
}

.ReplayItem:lang(he) {
    margin: 0px 0px 0 20px;
}

.ReplayItem:hover {
    border: 1px solid var(--pale-grey);
    outline: 1px solid var(--pale-grey);
}

.selected,
.selected:hover {
    border: 1px solid transparent;
    outline: 1px solid var(--salmon);
    z-index: 10;
}

.ReplayItem__Container {
display: flex;
}

.ReplayItem__Container_Icon > img {
    margin-top: 5px;
    width: 40px;
    height: 40px;
}

.ReplayItem_content {
    padding: 0 10px;
}

.ReplayItem_header {
    font-size: 18px;
    color: var(--dusk-two);
    padding: 0 0 5px 0;
}

.ReplayItem_description {
    font-size: 16px;
    color: #999ea8;
    padding: 0 0 5px 0;
}

.ReplayItem_date {
    font-size: 14px;
    color: #999ea8;
    padding: 0 0 5px 0;
}

.ReplayItem_Arrow {
    display: none;
}

.notRead {
    background-color: var(--very-light-pink);
}

@media (max-width: 1600px) {
    .ReplayItem {
        max-width: 470px;
        width: auto;
        width: initial;
    }

    .Requests_listContainer ul {
        width: auto;
        width: initial;
    }

}


@media (max-width: 1200px) {
    .ReplayItem {
        /*width: 350px;*/
    }

    .ReplayItem__Container_Icon > img {
        width: 40px;
    }

    .Requests_listContainer {
        height: calc(100vh - 350px);
    }
}

@media (max-width: 768px) {

    .Requests_listContainer {
        height: auto;
        height: initial;
    }

    .Requests {
        margin-top: 0;
    }

    .ReplaysList_hidden,
    .Requests_listContainer_hidden{
        display: none;
    }

    .ReplaysList {
        width: 100%;
        overflow-y: visible;
        overflow-y: initial;
    }

    .Requests_listContainer ul {
        overflow-y: visible;
        overflow-y: initial;
    }

    .ReplayItem {
        -webkit-margin-start: 0;
                margin-inline-start: 0;
        width: 100%;
        max-width: none;
        max-width: initial;
    }

    .ReplayItem_Arrow {
        align-self: center;
        display: inline-block;
        width: 24px;
        height: 24px;
    }

    .ReplayItem__Container_Icon > img {
        width: auto;
        width: initial;
    }

    .ReplayItem_header {
        font-size: 16px;
    }

    .ReplayItem_description {
        font-size: 13px;
    }

    .ReplayItem_date {
        font-size: 12px;
    }

}

.ReplyBody {
    padding-right: 50px;
    padding-left: 50px;
    margin-top: 12px;
    flex: 4 1;
}

.ReplyBody__Header_Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ReplyBody__Header {
    padding-bottom: 30px;
}

.ReplyBody__Header__Company {
    display: flex;
    align-items: center;
    /*color: var(--salmon);*/
    font-size: 24px;
}

.ReplyBody__Header__Company_Icon {
    width: 50px;
    height: 50px;
}

.ReplyBody__Header__Company_Icon img {
    height: 100%;
}



.ReplyBody__Header__Company__Description  {
    padding: 0 10px;
    color: var(--dusk-two);
}

.AlertHeader {
    color: var(--salmon);
}

.ReplyBody__Header_MuniAddress,
.ReplyBody__Header__Date {
    padding: 2px 0;
    font-size: 14px;
    color: var(--cool-gray);
}

.alertInfoWaterMeter {
    font-size: 18px;
    margin-top: 20px;
    color: var(--dusk-two);
}

.ReplyBody__ReturnButton {
    display: none;
}

.ReplyBody__ReturnButton > img {
    margin: 0 -15px;
}

.ReplyBody__ReturnButton p {
    padding: 0 15px;
    color: var(--azure);
    font-size: 16px;
}


.Visible {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ReplyBody__Content {
    color: var(--cool-gray);
}

.ReplyBody__Content__Request {
    width: 40%;
    overflow-wrap: break-word;
}

.ReplyBody__Content > * {
 margin-bottom: 20px;
}

.ReplyBody__Content__Reply {
    color: var(--dusk-two);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.ReplyBody__Content__Reply p {
    color: var(--dusk-two);
    margin: 0;
}

p.ReplyBody__Content__Reply__Body {
    margin-top: 20px;
}

.alertInfoText {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 45px;
}

.alertsCallContacts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.alertInfoCall {
    font-size: 18px;
    color: var(--dusk);
    flex: 0 0 auto;
}

.alertSep {
    /*width: 100%;*/
    flex: 1 1 auto;
    border: dashed 1px #d0d5d9;
    margin: 0 10px;
}

.alert_ConfirmButton {
    align-self: flex-start;
    background-color: transparent;
    margin-left: -26px;
    margin-right: -16px;
}

@media (max-width: 1200px) {
    .ReplayItem {
        padding: 10px;
    }

    .ReplyBody {
        padding: 0 10px;
    }

    .ReplyBody__Content__Request {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .ReplyBody__ReturnButton {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .ReplyBody {
        padding:10px 0;
    }

    .ReplyBody__Header__Company__Description {
        font-size: 18px;
    }

    .alertInfoWaterMeter{
        margin-top: 5px;
        font-size: 16px;
    }

    .ReplyBody__Header_MuniAddress,
    .ReplyBody__Header__Date{
        font-size: 13px;
    }

    .ReplyBody__Header{
        padding-bottom: 20px;
    }

    .alertsCallContacts {
        display: inline;
        display: initial;
    }

    .alertSep{
        display: none;
    }

    .alert_ConfirmButton{
        margin-top: 20px;
    }

    .ReplyBody__Content__Request {
        width: 100%;
    }
}

.alertContainer {
    margin: 20px 90px;
}

@media (max-width: 1100px) {

    .alertContainer {
        margin: 20px 140px;
    }
}

@media (max-width: 768px) {
    .alertContainer{
        margin: 0 15px;
    }
}

.NewRequest {
    font-size: 18px;
    width: 500px;
    margin: 0 140px 80px;
}

.NewRequest h1 {
    color: var(--dusk-two);
    font-size: 24px;
}

.NewRequest form {
    color: var(--cool-gray);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
}

.NewRequest_container {
    padding: 10px 0;
    width: 100%;
}

.NewRequest_container > * {
    padding: 5px 0;
}

#Subject > * {
    direction: rtl;
}

.NewRequest__TextArea {
    width: 100%;
    font-size: 18px;
    font-weight: 300;
    padding: 14px 20px;
    height: 168px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e0e0e0;
    background-color: #ffffff;
    color: var(--dusk);
}

.NewRequest__TextArea:lang(he) {
    font-family: Open Sans Hebrew, sans-serif;
}

.NewRequest__TextArea::-webkit-input-placeholder {
    font-size: 18px;
    font-weight: 300;
    font-family: Lato, sans-serif;
}

.NewRequest__TextArea:-ms-input-placeholder {
    font-size: 18px;
    font-weight: 300;
    font-family: Lato, sans-serif;
}

.NewRequest__TextArea::placeholder {
    font-size: 18px;
    font-weight: 300;
    font-family: Lato, sans-serif;
}

.NewRequest__TextArea:lang(he)::-webkit-input-placeholder {
    font-family: Open Sans Hebrew, sans-serif;
}

.NewRequest__TextArea:lang(he):-ms-input-placeholder {
    font-family: Open Sans Hebrew, sans-serif;
}

.NewRequest__TextArea:lang(he)::placeholder {
    font-family: Open Sans Hebrew, sans-serif;
}

.NewRequest_wait {
    display: none;
}

.NewRequest_sent {
    display: flex;
    font-size: 18px;
    color: var(--azure);
    padding: 20px 0 10px 0;
    justify-content: center;
}

.NewRequest_sent > *{
   margin: 0 5px;
}

.NewRequest_sent__Loader {
    box-sizing: border-box;
    height: 26.4px;
    width:26.4px;
    -webkit-animation: rotation 2s infinite linear;
            animation: rotation 2s infinite linear;
    -webkit-filter: invert(62%) sepia(98%) saturate(2631%) hue-rotate(175deg) brightness(99%) contrast(104%);
            filter: invert(62%) sepia(98%) saturate(2631%) hue-rotate(175deg) brightness(99%) contrast(104%);
}

.HasSent {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 18px;
    color: var(--azure);
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
    to {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
    to {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

.NewRequest__Button {
    box-sizing: border-box;
    width: 190px;
    height: 48px;
    border-radius: 24px;
    box-shadow: 0 10px 20px 0 rgba(0, 47, 189, 0.2);
    background-color: #003fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}

.NewRequest_contactsContainer {
    margin: 20px 0;
    /*display: flex;*/
}

.detailRow {
    display: flex;
    /*margin: 0 10px;*/
}

.detailRow * {
    margin: 0 5px;
}

.hidden {
    display: none;
}

@media (max-width: 1250px) {
    .NewRequest {
        margin: 0 140px 190px;
    }
}


@media (max-width: 768px) {
    .NewRequest {
        width: calc(100% - 60px);
        margin: 20px 30px;
        display: flex;
        flex-flow: wrap column;
        align-items: center;
        padding: 0;
    }

    .NewRequest_wizard_container {
        width: 100%;
    }

    .NewRequest_wizard_container h1 {
        text-align: center;
    }

    .NewRequest h1 {
        font-size: 22px;
    }

    .NewRequest form {
        align-items: center;
        width: 100%;
    }

    .NewRequest_sent {
        flex-flow: wrap column-reverse;
        align-items: center;
    }

    .NewRequest_sent > * {
        margin: 10px;
    }

    .NewRequest_container_label {
        display: none;
    }

    .NewRequest_container {
        display: flex;
        justify-content: center;
    }

    /*.NewRequest_container input,*/
    .NewRequest_container textarea {
        box-shadow: none;
        border: none;
        border-bottom: 1px solid #d0d5d9;
        /*height: initial;*/
        font-size: 18px;
        border-radius: 0;
        border-radius: initial;
        padding: 0;
        height: 37px;
    }

    .mobile-hidden {
        display: none;
    }

}

.landing_container  {
    position: relative;
}

.landing_logoContainer {
    display: flex;
    direction: ltr;
    margin-right: 140px;
}

.lpBgTop {
    width: 820px;
    height: 705px;
    object-fit: contain;
}

.lpBgTopMob {
    width: 100%;
    object-fit: contain;
}

.lpBigImage {
    width: 665px;
    height: 500px;
    object-fit: contain;
}

.lpBigImageMob {
    object-fit: contain;
}

.lpMonitorLabel {
    max-width: 473px;
    font-size: 33px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c63d7;
}

.lpMonitorLabelMob {
    font-size: 27px;
    text-align: center;
    color: #3c63d7;
    padding: 0 30px;
}

.lpButtonsContainer {
    display: flex;
    align-items: center;
    margin-top: 30px;
    justify-content: flex-start;
    /*margin-right: calc(100% - 493px);*/
}

.lpImage {
    object-fit: contain;
    width: 41px;
    height: 42px;
}

.lpButton {
    object-fit: contain;
}

.JoinButton,
.LoginButton {
    padding: 14px 30px;
    background: #003FFF;
    box-shadow: 0 10px 20px rgba(0, 47, 189, 0.2);
    border-radius: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    width: 200px;
    display: flex;
    justify-content: center;
}

.LoginButton {
    color: #003FFF;
    background: #FFFFFF;
    border: 1px solid #003FFF;
}

.LoginButton:hover {
    color: #003FFF;
    background: #f5f0f0;
}

.JoinButton:hover {
    background: #3668fa;
    color: #FFFFFF;
}

.LandingButtonDivider {
    width: 20px;
}

.lpButtonMob {
    object-fit: contain;
    width: 130px;
    height: 70px;
}

.lpButtonTitle {
    height: 27px;
    font-size: 22px;
    font-weight: 700;
    color: #000000;
}

.lpButtonSubTitle {
    width: 219px;
    height: 61px;
    text-align: center;

    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9f9f9f;
}

.alsoAvailable {
     font-size: 24px;
     font-weight: bold;
     font-style: normal;
     font-stretch: normal;
     line-height: normal;
     letter-spacing: normal;
     color: #000000;
 }

.alsoAvailableMob {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    display: flex;
    justify-content: center;
    margin-top: 80px;
}

.Landing_BottomLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Landing_Bottom_TitleLabel {
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;
    margin-top: 10px;
    z-index: 1;
}

.Landing_Bottom_SubtitleLabel {
    font-size: 14px;
    font-weight: 300;
    color: #FFFFFF;
    margin-top: 10px;
    z-index: 1;
}

.Landing_Bottom_background {
    position: absolute;
    bottom: 65px;
    width: 100%;
    z-index: -1;
}

@media (max-width: 1200px) {
    .Landing_Bottom_background {
        bottom: 140px;
    }

}

@media (max-width: 768px) {

    .landing_container  {
        position: static;
        position: initial;
    }

    .Landing_BottomLogo {
        margin: 190px auto 100px;
    }

    .Landing_Bottom_background {
        bottom: 0;
        margin-bottom: -165px;
        overflow-y: hidden;
    }

    .lpButtonSubTitle {
        width: 250px;
    }

    .LandingButtonDivider {
        max-width: 20px;
        flex-basis: 100%;
    }

}

.header_item_landing {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50px;
    right: 150px;
}


.language-selector {
    position: relative;
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    justify-content: center;
}

.language-selector__image {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
}

.language-selector__dropdown {
    display: none;
    position: absolute;
    right: 0;
    z-index: 9999;
    background-color: var(--white);
    box-shadow: 0 10px 10px #666;
}

.language-selector__dropdown .language-item:not(:last-child) {
    margin-bottom: 10px;
}

.language-selector__dropdown_opened {
    display: block;
}

.language-item {
    width: 100%;
}

.language-item__text {
    color: black;
}

.language-item_active {
    border-bottom: 0px;
    text-decoration: underline;
    color: var(--carolina-blue);
}

.privacyPolicy_container {
    padding: 10px;
    height: calc(100% - 70px);
}

.privacyPolicyTitle {
    height: 29px;
    font-size: 24px;
    color: var(--dusk);
    white-space: pre-line;
}

.applicationPrivacyStatement {
    height: 22px;
    font-size: 18px;
    font-weight: 500;
    color: var(--dusk);
}

.privacyText {
    width: 100%;
    height: 350px;

    font-size: 18px;
    font-weight: 500;
    color: #999ea8;
    resize: none;
}

/*.desktopContactsContent {*/
/*    padding: 40px;*/
/*}*/

/*.mobileContactsContent {*/
/*    padding: 20px 15px;*/
/*}*/

.contactsContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 50px 140px;
    /*direction: rtl;*/
}


.RequestContacts_Container {
    /*direction: rtl;*/
    margin-top: 40px;
    width: 100%;
}

.contactsContainer_title {
    font-size: 24px;
    color: var(--dusk);
    margin-bottom: 0;

}

.RequestContacts_Container p {
    margin: 5px 0;
}

.RequestContacts_row {
    display: flex;
    align-items: center;
    /*direction: rtl;*/
}

.RequestContacts_row p {
    margin: 2px 0;
}

@media (max-width: 768px) {
    .contactsContainer {
        margin: 20px 15px;
    }

}

/*.desktopContactsContent .contactsContainer {*/
/*    width: 60%;*/
/*}*/

/*.contactsInfo {*/
/*    margin: 47px 0;*/
/*}*/

/*.contactsDetailsTitle {*/
/*    margin: 0;*/
/*}*/

/*.detailRow {*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

/*.detailRow p {*/
/*    color: var(--dusk);*/
/*    font-size: 18px;*/
/*    margin-inline-start: 16px;*/
/*}*/

/*.detailRow img {*/
/*    max-width: 24px;*/
/*}*/

.AccessContainer {
    /*direction: rtl;*/
    padding: 32px 140px 120px 140px;
    /*font-family: Open Sans Hebrew, sans-serif;*/
    /*height: calc(100% - 300px);*/
    /*overflow: auto;*/
}

.AccessContainer h3 {
    font-size: 24px;
}

.AccessContainer_header {
    /*text-align: right;*/
    padding-bottom: 20px;
    margin-top: 40px;
}

.AccessContainer p {
    -webkit-margin-after: 2px;
            margin-block-end: 2px;
    -webkit-margin-before: 2px;
            margin-block-start: 2px;
}

.AccessContainer h3 {
    -webkit-margin-after: 2px;
            margin-block-end: 2px;
}

.AccessContainer li {
    color: var(--cool-gray);
}

.AccessContainer ul {
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 40px;
            margin-block-end: 40px;
}

.AccessContainer_contacts {
    margin: 20px 0 40px 0;
}

.AccessContainer_row {
    display: flex;
    align-items: center;
}

.AccessContainer a {
    margin: 0 5px;
}

@media (max-width: 768px) {


    .AccessContainer {
        /*direction: rtl;*/
        padding: 0 20px 20px 20px;
    }

}

.desktopAboutContent {
    /*padding: 40px 0;*/
    margin: 50px 140px;
}

.mobileAboutContent {
    padding: 20px 15px;
}

.aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.mobileAboutContent .title,
.desktopAboutContent .title {
    margin-bottom: 47px;
}

.version {
    padding: 0;
    margin-top: -30px;
}

.aboutContainer .row {
    display: flex;
    /*margin: 0 -35px;*/
    flex-wrap: wrap;
}

.mobileAboutContent .aboutContainer .row {
    margin: 0;
}

.aboutInfo {
    flex: 1 1;
    /*padding: 0 35px;*/
    margin: 0;
}

.aboutContainer_divider {
    width: 35px;
}

.aboutImg {
    /*padding: 0 35px;*/
}

.mobileAboutContent .aboutInfo,
.mobileAboutContent .aboutImg {
    flex: auto;
    width: 100%;
    padding: 0;
    padding-bottom: 20px;
}

@media (max-width: 1350px) {
    .desktopAboutContent {
        margin-bottom: 140px;
    }
}

.Registered-e-mail {
    height: 20px;

    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.Rectangle {
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
    font-size: 16px;
}

.btnLogin {
    object-fit: contain;
}

.subTitleMob {
    height: 39px;

    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.mainTitleMob {
    height: 29px;

    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--dusk);
}

