.settingsContainer {
    display: flex;
    padding: 50px 140px 200px;
}

.settingsContainer_params {
    display: flex;
    flex: 5 1;
    flex-direction: column;
    align-items: flex-start;
}

.settingsContainer_params_togglesContainer{
    padding: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.settingsContainer_params_togglesContainer > div:nth-child(1) * {
    color: var(--dusk);
}

.settingsContainer_params_togglesContainer_row {
    display: flex;
    align-items: center;
    width: 100%;
}

.settingsContainer_params_togglesContainer_row_header {
    background-color: #f7faff;
}

/*.title {*/
/*    font-size: 24px;*/
/*    color: var(--dusk);*/
/*}*/

.alertParamsTitle {
    font-weight: 700;
}

.alertSettingsRectangle {
    height: 48px;
    background-color: #f7faff;
}

.alertTitle {
    font-size: 16px;
    padding: 14px 0;
    color: var(--dusk);
    min-width: 160px;
}

.alertType {
    font-size: 16px;
    padding: 14px 30px;
    color: #999ea8;
    flex-grow: 1;
}

.alertToggle {
    min-width: 220px;
    display: flex;
    justify-content: center;
}

.settingsContainer_params_meters {
    margin-top: 10px;
}

.settingsContainer_params_meters_header {
    display: flex;
    padding: 0 30px;
}

.settingsContainer_params_meters_list {
    margin: 30px;
}

.settingsContainer_params_meters_list_row {
    display: flex;
}

.settingsContainer_account {
    display: flex;
    flex: 4;
    justify-content: flex-end;
}

.accountBox {
    flex: 0.8;
    padding: 30px 15px 10px;
    background-color: #f9f9f9;
    height: max-content;
}

.accountBox_buttonWrapper{
    width: 100%;
    direction: ltr;
    margin: 0 15px;
}

.accountBox_buttonWrapper:lang(he) {
    margin: 0;
    padding: 0 30px;
}

.settingsContainer_account_button {
    margin-top: 20px;
    align-self: flex-start;
    width: 257px;
}

.settingsContainer_account_button:lang(he) {

}

.settingsContainer_account_button img {
    height: 22px;
}

.buttonDivider {
    width: 12px;
}

.buttonDivider:lang(he) {
    width: 30px;
}

.buttonText {
    flex: 1;
}

.accountKey {
    margin: 10px 0;
    font-size: 18px;
    color: #999ea8;
}

.accountLink {
    color: var(--azure);
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
}

.accountKeyMobile {
    font-size: 18px;
    color: #999ea8;
}

.accountValue {
    margin: 10px 0;
    font-size: 18px;
    color: var(--dusk);
    height: 22px;
}

.phoneNumber {
    direction: ltr;
}

.thinSep {
    width: 100%;
    height: 1px;
    background-color: #d0d5d9;
}

.myWaterMeters {
    font-size: 18px;
    color: var(--dusk);
    margin: 0 15px;
}

.waterMeterItem {
    font-size: 13px;
    color: var(--dusk);
}

.waterMeterItem:first-child {
    margin-inline-end: 5px;
}

.btnPassword {
    object-fit: contain;
}

@media (max-width: 1350px) {
    .settingsContainer {
        flex-direction: column;
    }

    .settingsContainer_params{
        align-items: center;
    }

    .title {
        width: 100%;
    }

    .alertToggle {
        min-width: 120px;
    }

    .settingsContainer_account {
        justify-content: center;
    }

    .accountBox_buttonWrapper {
        margin: 0;
    }

    .settingsContainer_account_button{
        margin: 20px auto 0;

    }
}

@media (max-width: 768px) {
    .alertToggle {
        min-width: 100px;
    }

    .settingsContainer_params_togglesContainer {
        width: auto;
    }

    .settingsContainer_account {
        width: 100%;
        justify-content: unset;
    }

    .accountBox {
        flex: unset;
        width: 100%;
    }

    .settingsContainer {
        padding: 20px 15px;
    }

    .title {
        font-size: 22px;
    }

    .accountKey {
        font-size: 16px;
    }

}
