@import "../../colors.css";

.accountNotFound {
    height: 22px;

    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #46557a;
    color: var(--dusk);
}

.tryAnother {
    height: 22px;

    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}
