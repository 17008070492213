.registerFinish_Container {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 180px;
}

.passwordRulesText {
    height: 16px;
    font-size: 13px;
    font-weight: normal;
    color: #999ea8;
}

.signingUp {
    /*height: 22px;*/
    font-weight: 400;
    font-size: 18px;
    color: #999ea8;
    margin: 0;
}

.btnFinishAndLogin {
    object-fit: contain;
}

.rectangleRegisterFinish {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

@media (max-width: 768px) {
    .signingUp {
        font-size: 16px;
    }

    .rectangleRegisterFinish {
        max-width: 330px;
    }

    .registerFinish_Container {
        margin-bottom: 20px;
    }
}
