@import '../../colors.css';

.footer {
    display: none;
    background-color: var(--dark);
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: space-between;
    direction: ltr;
    padding: 0 140px;
}

@media only screen {
    .footer {
        display: flex;
        z-index: 10;
    }
}

.footerMobile {
    background-color: var(--dark);
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding: 20px 0 14px 0;
}

.allRights {
    color: var(--dusk);
    font-size: 10px;
}

.footerLink {
    text-decoration: none;
    font-size: 12px;
    color: #ffffff;
    margin: 10px 10px;
    cursor: pointer;
}

.footerLink:hover {
    opacity: 0.7;
    color: #ffffff;
}

.logoMWAFooter {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin: auto 5px;
}

.myWaterAdvisor {
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
    font-family:  Lato, sans-serif;
}

.footerLink_Divider {
    background-color: var(--dodger-blue-3);
    width: 2px;
    margin-left: 8px;
    margin-right: 8px;
    height: 14px;
    align-self: center;
}

@media (max-width: 1200px) {
    .footer {
        flex-direction: column-reverse;
    }
}

@media (max-width: 768px) {
    .footerLink {
        margin: 4px 10px;
        font-size: 14px;
    }
}

