@import '../colors.css';

.waterConsumption {
    background-image: linear-gradient(to top, #3eb3f9, #2b74fa);
}

.meVsSimilar {
    background-color: #d9d9d9;
}

.previousYear2016 {
    background-image: linear-gradient(to top, #c6c6c6, #8d8d8d);
}

.previousYear2017 {
    background-image: linear-gradient(to top, #1fede0, #18b6c0);
}

.previousYear2018 {
    background-image: linear-gradient(to top, #3eb3f9, #2b74fa);
}

.chartAreaWrapper {
    overflow-x: scroll;
}

.chartLegend_Wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: -120px;
    margin-left: 40px;
    /*margin-right: 40px;*/
}

.chartLegendColumnDescription {
    display: flex;
    justify-content: flex-start;
}

.chartLegendColumnDescription * {
    display: flex;
    align-items: center;
}

.chartLegendColumnDescription > div > p {
    margin: 30px 10px;
    color: var(--dusk-two);
    font-size: 18px;
}

.chartLegendColumnDescription_divider {
    width: 40px;
}

.estimateBox,
.realBox,
.belowBox,
.underBox {
    width: 16px;
    height: 16px;
}

.belowBox {
    background-color: var(--lightish-blue);
}

.underBox {
    background-color: var(--reddish-orange);
}

.estimateBox {
    background-color: var(--dodger-blue-Two);
}

.realBox {
    background-color: var(--aqua);
}

.chartLegendStatistics {
    display: flex;
    margin: 0px -20px;
}

.borderBottom {
    border-bottom-color: red;
    border-bottom-style: solid;
    border-bottom-width: thick;
}

.chartLegendItem {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    font-size: 18px;
    color: var(--cool-gray);
}

.chartLegend_ValuesContainer {
    display: flex;
    align-items: baseline;
    font-size: 30px;
    color: var(--azure);
}

.chartLegend_ValuesContainer > div:nth-child(1) {
    font-family: Lato, sans-serif;
}

.chartLegendUnits {
    font-size: 13px;
    margin: 0 3px;
    color: var(--cool-gray);
}

@media (max-width: 768px) {
    .chartLegendUnits {
        font-size: 11px;
    }

    .chartLegendItem {
        margin: 0 10px;
    }

    .chartLegend_Wrapper {
        width: 100%;
        margin-top: -90px;
        margin-left: 0;
        margin-right: 0;
    }

    .chartLegendColumnDescription {
        justify-content: center;
    }

    .chartLegendStatistics {
        flex-wrap: wrap;
        row-gap: 20px;
        margin-left: 0;
        margin-right: 0;
        justify-content: space-around;
    }

    .chartLegendColumnDescription > div > p {
        font-size: 15px;
    }

    .chartLegendItem {
        font-size: 15px;
    }

    .chartLegend_ValuesContainer {
        font-size: 18px;
    }

}
