@import 'colors.css';

.title {
    color: var(--dusk);
    font-size: 20px;
    text-align: center;
}

.titleMobile {
    font-size: 14px;
}

.eyeButton {
    float: right;
    margin-left: -40px;
    background-color: transparent;
    margin-top: 16px;
    align-self: start;
}

.eyeButton:is(:lang(he), :lang(ar)) {
    margin-right: -40px;
    margin-left: 0;
}

::-webkit-input-placeholder {
    color: #999ea8;
}

::-moz-placeholder {
    color: #999ea8;
    opacity: 1;
}

a {
    color: var(--azure);
    cursor: pointer;
    text-decoration: underline;
}

a:hover {
    color: #003fff;
}

button:hover {
    opacity: 0.7;
}

button:focus {
    outline: none;
}

button {
    outline: none;
    border: 0;
    cursor: pointer;
    background-color: transparent;
}

.blueButton,
.WhiteButton,
.purpleButton {
    padding: 14px 18px;
    background: #003FFF;
    box-shadow: 0 10px 20px rgba(0, 47, 189, 0.2);
    border-radius: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    display: flex;
}

.WhiteButton {
    color: #003FFF;
    background: #FFFFFF;
    border: 1px solid #003FFF;
}

.purpleButton {
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 95px;
    height: 38px;
    /*padding: 9px 12px;*/
    color: #84A2FF;
    background: #FFFFFF;
    border: 1px solid #84A2FF;
}

.WhiteButton:hover {
    color: #003FFF;
    background: #f5f0f0;
}

.purpleButton:hover {
    color: #7998f6;
    background: #f5f0f0;
}

.blueButton:hover {
    background: #3668fa;
    color: #FFFFFF;
}

.transparent {
    background-color: transparent;
}

.inputError {
    color: var(--salmon);
    font-size: 14px;
}

.Site {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.Site-content {
    flex: 1 0 auto;
    padding: 2em 2em 0;
    width: 100%;
}

.Site-content:after {
    content: '\00a0';
    display: block;
    margin-top: 2em;
    height: 0;
    visibility: hidden;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}

.welcomeText {
    margin: auto;
    font-size: 16px;
    padding: 0 20px;
    font-weight: 400;
    color: #ffffff;
}

.sep {
    width: 2px;
    background-image: linear-gradient(to top, #3659c3 19%, #3c63d8 63%);
}

.ToLandingLink {
    display: flex;
    align-self: baseline;
    align-items: center;
    margin-right: 123px;
    margin-left: 123px;
    text-decoration: none;
    margin-top: 5px;
}

.ToLandingLink:hover {
    color: var(--azure);
}

.logoutTabButton {
    margin: 10px 140px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.alertsBellContainer {
    width: 30px;
    height: 30px;
    align-items: center;
    margin: 15px 5px 0 5px;
    display: flex;
    align-self: center;
}

.alertsBell {
    width: 20px;

}

.alertsCounter {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: #ff4f4f;
    display: flex;
    color: #ffffff;
    justify-content: center;
    font-size: 12px;
    top: -4px;
    right: 0px;
    left: 10px;
    position: absolute;
    align-self: flex-start;
    align-items: center;
}

.activeMenuIndicator {
    background-color: #84a2ff;
    height: 3px;
    /*border-bottom: 3px solid  #84a2ff;*/
    width: 100%;
    margin-top: 10px;
}

.dropdown_inputContainer {
    display: flex;
}

.dropdownInput {
    width: 500px;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    text-align: inherit;
    font-size: 18px;
    font-weight: 300;
    color: #999EA8;
    padding: 0 20px;
}

.dropdown_button {
    cursor: pointer;
    position: relative;
    margin: 0 -25px;
    padding: 0 0 2px;
    /*padding: 10px;*/
    z-index: 10;
}

.dropdown_button_close {
    cursor: pointer;
    position: relative;
    margin: 0 -25px;
    padding: 0 0 -2px;
    /*padding: 10px;*/
    z-index: 10;
}


.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    cursor: pointer;
}

.dropdown-content div:hover {
    background-color: #f1f1f1
}

.dropdown-content div {
    /* direction: rtl; */
    color: var(--dusk);
    font-size: 18px;
    padding: 12px 16px;
    display: block;
}

.dropdown2-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    cursor: pointer;
}

.dropdown2-content div:hover {
    background-color: #f1f1f1
}

.dropdown2-content div {
    direction: rtl;
    color: var(--dusk);
    font-size: 18px;
    padding: 12px 16px;
    display: block;
}

.dropdown2:hover .dropdown2-content {
    display: block;
}

/* .dropdown2-content {
    display: block;
} */

.dropdown-content {
    display: block;
}

.visible {
    display: block;
}

.top-bar-desktop {
    padding-inline: 145px;
}

@media (max-width: 1200px) {
    .top-bar-desktop {
        padding-inline: 55px;
    }
}

@media (max-width: 768px) {

    .inputError {
        max-width: 300px;
    }

    .logoutTabButton {
        margin: 10px 15px;
    }


    .ToLandingLink {
        margin: 5px 0;
        font-size: 15px;
    }

    .welcomeText {
        margin: 25px 0;
        padding: 0;
        font-size: 14px;
    }

    .alertsBellContainer {
        margin: -10px 5px 0 5px;
    }

    .NavbarLinkContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        height: 30px;
    }

    .activeMenuIndicatorUnderline {
        border-bottom: 3px solid #84a2ff;
    }

    .dropdown {
        width: 100%;
    }
    .dropdown2 {
        width: 100%;
    }

    .dropdownInput {
        width: 100%;
        border: none;
        border-bottom: 1px solid #D0D5D9;
        border-radius: initial;
        box-shadow: initial;
        padding: 0;

    }

}
