.NewRequest {
    font-size: 18px;
    width: 500px;
    margin: 0 140px 80px;
}

.NewRequest h1 {
    color: var(--dusk-two);
    font-size: 24px;
}

.NewRequest form {
    color: var(--cool-gray);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
}

.NewRequest_container {
    padding: 10px 0;
    width: 100%;
}

.NewRequest_container > * {
    padding: 5px 0;
}

#Subject > * {
    direction: rtl;
}

.NewRequest__TextArea {
    width: 100%;
    font-size: 18px;
    font-weight: 300;
    padding: 14px 20px;
    height: 168px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e0e0e0;
    background-color: #ffffff;
    color: var(--dusk);
}

.NewRequest__TextArea:lang(he) {
    font-family: Open Sans Hebrew, sans-serif;
}

.NewRequest__TextArea::placeholder {
    font-size: 18px;
    font-weight: 300;
    font-family: Lato, sans-serif;
}

.NewRequest__TextArea:lang(he)::placeholder {
    font-family: Open Sans Hebrew, sans-serif;
}

.NewRequest_wait {
    display: none;
}

.NewRequest_sent {
    display: flex;
    font-size: 18px;
    color: var(--azure);
    padding: 20px 0 10px 0;
    justify-content: center;
}

.NewRequest_sent > *{
   margin: 0 5px;
}

.NewRequest_sent__Loader {
    box-sizing: border-box;
    height: 26.4px;
    width:26.4px;
    animation: rotation 2s infinite linear;
    filter: invert(62%) sepia(98%) saturate(2631%) hue-rotate(175deg) brightness(99%) contrast(104%);
}

.HasSent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: var(--azure);
}

@keyframes rotation {
    from {
        transform: rotate(359deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.NewRequest__Button {
    box-sizing: border-box;
    width: 190px;
    height: 48px;
    border-radius: 24px;
    box-shadow: 0 10px 20px 0 rgba(0, 47, 189, 0.2);
    background-color: #003fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}

.NewRequest_contactsContainer {
    margin: 20px 0;
    /*display: flex;*/
}

.detailRow {
    display: flex;
    /*margin: 0 10px;*/
}

.detailRow * {
    margin: 0 5px;
}

.hidden {
    display: none;
}

@media (max-width: 1250px) {
    .NewRequest {
        margin: 0 140px 190px;
    }
}


@media (max-width: 768px) {
    .NewRequest {
        width: calc(100% - 60px);
        margin: 20px 30px;
        display: flex;
        flex-flow: wrap column;
        align-items: center;
        padding: 0;
    }

    .NewRequest_wizard_container {
        width: 100%;
    }

    .NewRequest_wizard_container h1 {
        text-align: center;
    }

    .NewRequest h1 {
        font-size: 22px;
    }

    .NewRequest form {
        align-items: center;
        width: 100%;
    }

    .NewRequest_sent {
        flex-flow: wrap column-reverse;
        align-items: center;
    }

    .NewRequest_sent > * {
        margin: 10px;
    }

    .NewRequest_container_label {
        display: none;
    }

    .NewRequest_container {
        display: flex;
        justify-content: center;
    }

    /*.NewRequest_container input,*/
    .NewRequest_container textarea {
        box-shadow: none;
        border: none;
        border-bottom: 1px solid #d0d5d9;
        /*height: initial;*/
        font-size: 18px;
        border-radius: initial;
        padding: 0;
        height: 37px;
    }

    .mobile-hidden {
        display: none;
    }

}
