@import '../../colors.css';

.header {
    height: 10%;
    padding: 40px 140px 40px 140px;
    display: flex;
    background-image: linear-gradient(
        to top,
        var(--warm-blue) 33%,
        var(--dodger-blue) 77%
    );
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

.header_item {
    display: flex;
    align-items: center;
}

.header_item_title {
    margin-inline-start: 10px;
}

.header_item_title_rtl {
    margin: auto 10px;
    direction: rtl;
}

.header_languageButton {
    cursor: pointer;
    margin: 10px;
    padding-bottom: 5px;
}

.header_languageButton > img {
    width: 35px;
    height: 20px;
}

.headerMobile {
    padding-left: 0;
}

.logoMWAHeader {
    height: 48px;
    object-fit: contain;
}

.logoMWAHeaderMob {
    object-fit: contain;
}

.headerTitleLabel {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    font-family:  Lato, sans-serif;
}

.headerSubtitleLabel {
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.headerSubtitleLabelMob {
    /**/
    font-size: 15px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.activeLanguage {
    border-bottom: 2px solid;
    color: var(--carolina-blue);
}

@media (max-width: 1200px) {
    .header {
        padding: 40px 55px;
    }
}

@media (max-width: 768px) {
    .header {
        padding: 20px 0px 20px 5px;
    }

    .logoMWAHeader {
        margin: auto 10px;
    }

    .header_item_title {
        margin-inline-start: 15px;
    }
}
