@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css);

body {
    margin: 0;
    padding: 0;
    font-family:  Lato, sans-serif;
}

#root > *:lang(he),
#root button:lang(he),
#root input:lang(he)
{
    font-family: Open Sans Hebrew, sans-serif;
}

button,
input{
    font-family:  Lato, sans-serif;
}

* {
    box-sizing: border-box;
}

@media print {
    @page {
        size: landscape;
    }

    canvas {
        width: 100% !important;
    }
}

p {
    color: var(--cool-gray);
}

h3 {
    font-size: 18px;
    color: var(--dusk);
}

img {
    max-width: 100%;
}
