@import "../../colors.css";

.dailyUsage {
    font-family: Lato;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}@import "../../colors.css";

.setMonthlyTitle {
    font-size: 24px;
    margin: 30px 0;
}

.dailyUsage {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.borderRect {
    font-size: 16px;
    direction: ltr;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    text-align: center;
}

.fromTo {
    font-size: 14px;
    color: var(--dusk);
}

.fromTo:lang(he) {
    /*font-weight: 700;*/
}

.chooseDateInput {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
    border: 0;
    margin-left: 10px;
    margin-right: 10px;
    width: 135px;
    background-color: #ffffff;
}

.datePickerDivider {
    background-color: var(--silver);
    width: 1px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    margin-top: 20px;
}

.vacationSep {
    height: 24px;
    width: 1px;
    background-color: #d0d5d9;
}

.monthlyLimitToggleContainer {
    display: flex;
    align-items: center;
    margin: 0 25px;
}

.Vacation_ButtonsContainer {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: transparent;
}

.Vacation_ButtonsContainer button {
    margin: 0 -15px;
}


@media (max-width: 768px) {
    .Vacation_ButtonsContainer button {
        margin: 0;
    }

    .setMonthlyTitle {
        font-size: 20px;
        text-align: center;
    }

    .dailyUsage {
        text-align: center;
    }

    .monthlyLimitToggleContainer {
        margin: 0 10px;
    }

    .borderRect {
        margin-top: 8px;
    }

    .fromTo {

    }

    .datePicker {
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-left: 15px;
        margin-right: 15px;
        padding: 0;
        background-color: transparent;
    }

    .chooseDateInput {
        width: 100px;
    }

    .datePickerDivider {
        margin: 20px 10px 0 10px;
    }

    .datePickerHorizontalDivider {
        height: 10px;
        border-bottom: 1px solid var(--silver);
    }


    .toggleContainer {
        margin-top: 20px;
        display: flex;
    }
}


.fromTo {
    font-family: Lato;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dusk);
}

.chooseDateInput {
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.vacationSep {
    height: 24px;
    width: 1px;
    background-color: #d0d5d9;
}
