.enterPrimary {
    height: 22px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.enterPrimaryMobile {
    height: 22px;

    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.rectangleMobile {
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.btn_get_code {
    object-fit: contain;
}

.country {
    font-size: 18px;
    font-weight: 300;
    color: #999ea8;
    border: none;
    outline: none;
    /*float: left;*/
    margin-left: 8px;
}

.phoneBox {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    max-width: 330px;
    width: 100%;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.phoneInput:focus {
    outline: 0;
}
