.Registered-e-mail {
    height: 20px;

    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.Rectangle {
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
    font-size: 16px;
}

.btnLogin {
    object-fit: contain;
}

.subTitleMob {
    height: 39px;

    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.mainTitleMob {
    height: 29px;

    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--dusk);
}
