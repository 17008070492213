.AccessContainer {
    /*direction: rtl;*/
    padding: 32px 140px 120px 140px;
    /*font-family: Open Sans Hebrew, sans-serif;*/
    /*height: calc(100% - 300px);*/
    /*overflow: auto;*/
}

.AccessContainer h3 {
    font-size: 24px;
}

.AccessContainer_header {
    /*text-align: right;*/
    padding-bottom: 20px;
    margin-top: 40px;
}

.AccessContainer p {
    margin-block-end: 2px;
    margin-block-start: 2px;
}

.AccessContainer h3 {
    margin-block-end: 2px;
}

.AccessContainer li {
    color: var(--cool-gray);
}

.AccessContainer ul {
    margin-block-start: 0;
    margin-block-end: 40px;
}

.AccessContainer_contacts {
    margin: 20px 0 40px 0;
}

.AccessContainer_row {
    display: flex;
    align-items: center;
}

.AccessContainer a {
    margin: 0 5px;
}

@media (max-width: 768px) {


    .AccessContainer {
        /*direction: rtl;*/
        padding: 0 20px 20px 20px;
    }

}
