
.Requests {
    display: flex;
    margin-top: 30px;
}

.Requests_listContainer,
.Requests_listContainer_hidden {
    display: flex;
    flex-flow: wrap column;
    flex: 3;
    height: calc(100vh - 450px);
}

.Requests_listContainer ul {
    overflow-y: auto;
    padding: 1px;
    width: fit-content;
}

.ReplaysList {
    overflow-y: auto;
}

.ReplaysList_hidden {
    flex: 3;
    overflow-y: auto;
    padding: 1px;
}

.ReplayItem {
    width: 470px;
    display: flex;
    justify-content: space-between;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--pale-grey);
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    border-top: 1px solid var(--pale-grey);
    outline: 1px solid transparent;
    padding: 10px 40px;
    cursor: pointer;
    margin: 0px 20px 0 0px;
}

.ReplayItem:lang(he) {
    margin: 0px 0px 0 20px;
}

.ReplayItem:hover {
    border: 1px solid var(--pale-grey);
    outline: 1px solid var(--pale-grey);
}

.selected,
.selected:hover {
    border: 1px solid transparent;
    outline: 1px solid var(--salmon);
    z-index: 10;
}

.ReplayItem__Container {
display: flex;
}

.ReplayItem__Container_Icon > img {
    margin-top: 5px;
    width: 40px;
    height: 40px;
}

.ReplayItem_content {
    padding: 0 10px;
}

.ReplayItem_header {
    font-size: 18px;
    color: var(--dusk-two);
    padding: 0 0 5px 0;
}

.ReplayItem_description {
    font-size: 16px;
    color: #999ea8;
    padding: 0 0 5px 0;
}

.ReplayItem_date {
    font-size: 14px;
    color: #999ea8;
    padding: 0 0 5px 0;
}

.ReplayItem_Arrow {
    display: none;
}

.notRead {
    background-color: var(--very-light-pink);
}

@media (max-width: 1600px) {
    .ReplayItem {
        max-width: 470px;
        width: initial;
    }

    .Requests_listContainer ul {
        width: initial;
    }

}


@media (max-width: 1200px) {
    .ReplayItem {
        /*width: 350px;*/
    }

    .ReplayItem__Container_Icon > img {
        width: 40px;
    }

    .Requests_listContainer {
        height: calc(100vh - 350px);
    }
}

@media (max-width: 768px) {

    .Requests_listContainer {
        height: initial;
    }

    .Requests {
        margin-top: 0;
    }

    .ReplaysList_hidden,
    .Requests_listContainer_hidden{
        display: none;
    }

    .ReplaysList {
        width: 100%;
        overflow-y: initial;
    }

    .Requests_listContainer ul {
        overflow-y: initial;
    }

    .ReplayItem {
        margin-inline-start: 0;
        width: 100%;
        max-width: initial;
    }

    .ReplayItem_Arrow {
        align-self: center;
        display: inline-block;
        width: 24px;
        height: 24px;
    }

    .ReplayItem__Container_Icon > img {
        width: initial;
    }

    .ReplayItem_header {
        font-size: 16px;
    }

    .ReplayItem_description {
        font-size: 13px;
    }

    .ReplayItem_date {
        font-size: 12px;
    }

}
