.choosePassword {
    height: 20px;

    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.titleMobilerectangleMobile {
    width: 280px;
}

.btnSavePassword {
    object-fit: contain;
}

.titleMobile {
    font-size: 14px;
    color: var(--dusk);
    text-align: center;
}
