.Enter-your-e-mail-below-and-we-will-send-you-the-i {
    height: 22px;
    font-size: 18px;
    color: #999ea8;
}

.btn_send_instructions {
    padding: 14px 20px;
    justify-content: center;
    width: 180px;
}

@media (max-width: 768px) {
    .Enter-your-e-mail-below-and-we-will-send-you-the-i {
        font-size: 16px;
        margin: 2px auto 0;
        height: auto;
    }

}
