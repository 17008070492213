@import "../../colors.css";

.setMonthlyTitle {

    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dusk);
}

.monthlyLimitUnits {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.btnSave {
    object-fit: contain;
}

.borderRect {
    width: 97px;
    height: 38px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #d0d5d9;
}
