@import "../../colors.css";

.signUpCell {
    width: 628px;
    height: 16%;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
}

.signUpCellMobile {
    height: 16%;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
}

.Sign-up-with-cell-phone-number {
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--azure);
}

.Phone-number-registered-with-your-utility-or-servi {
    height: 29px;

    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.Need-help-Call-your-utility-office {
    height: 20%;

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: normal;
    color: var(--dusk);
}

.Your-water-utility-name-can-be-found-in-the-monthl {
    height: 22px;

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.Your-water-utility-name-can-be-found-in-the-monthl-mobile {
    height: 22px;

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.Rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.RectangleMob {
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.registerTitle {
    color: var(--dusk);
    font-size: 24px;
    text-align: center;
}

.registerTitleMobile {
    font-size: 14px;
}
