.title {

    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dusk);
}

.rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.rectangleMob {
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.country {
    font-size: 18px;
    font-weight: 300;
    /*font-style: normal;*/
    /*font-stretch: normal;*/
    /*line-height: normal;*/
    /*letter-spacing: normal;*/
    color: #999ea8;
}

.phoneInput:focus {
    outline: 0;
}
