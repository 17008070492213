
.inputTitle {
    width: 100%;
    /*height: 20px;*/
    font-size: 16px;
    color: #999ea8;
    font-weight: 400;
}

.inputTitleMobile {
    height: 20px;

    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999ea8;
}

.rectangle {
    width: 380px;
    height: 46px;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
}

.btnContinue {
    object-fit: contain;
}

.registerDropdown {
    width: 500px;
    font-size: 16px;
    font-weight: 500;
    Padding: 0 10px;
    color: initial;
    /*letter-spacing: -0.35pt;*/
}


.registerDropdown::placeholder,
.registerDropdown:-ms-input-placeholder,
.registerDropdown::-ms-input-placeholder{
    color: #999EA8;
    opacity: 1;
}

.placeholder {
    color: #999EA8;
}

.dropdown-content-register div {
    font-size: 16px;
}

@media (max-width: 768px) {

    .RegisterForm {
        margin: 0 15px;
        width: 100%;
    }

    .inputTitle {
        font-size: 14px;
    }


    .registerFinish_Container {
        margin: 20px 15px;
    }

    .registerDropdown {
        width: 100%;
        border: 1px solid #E0E0E0;
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        margin: 0 0 10px;
    }


}
